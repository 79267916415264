import React from 'react';

const Connector = () => {
    return <div className={"connector-container"}>
        <div className={"circle"}></div>
        <div className={"outer"}></div>
        <div className={"line"}></div>
        <div className={"circle bottom"}></div>
        <div className={"outer bottom"}></div>
    </div>
}

export default Connector;