import React from 'react';

import ProductTemplate from '../../../../templates/productNew';
import Carousel from './carousel';

const Product = () => {
    const title = "Paraqum SMS";
    const watermark = "SUBSCRIBER MANAGER"
    const mockup = "/assets/screens/subscriberManager/DeviceMockup/subscriberManagerMockup.png";
    const features = [
        {
          title: "Bandwidth management",
          description: "Paraqum SMS offers detailed bandwidth control for ISPs addressing revenue challenges and increased demand. Customize subscriber packages, prioritize applications, and enhance overall Quality of Experience (QoE)."
        },
        {
          title: "Application-aware plans",
          description: "Tailor service plans based on user preferences, implementing application-aware policies for maximum and guaranteed bandwidth limits. Prioritize, restrict, or allow specific apps to provide personalized packages and improved subscriber experiences."
        },
        {
          title: "Subscriber-based data plans",
          description: "Innovate with personalized data plans, setting desired bandwidth limitations. Paraqum SMS enables ISPs to offer granular control, ensuring individualized preferences and improved QoE for diverse user needs."
        },
        {
          title: "Subscriber-oriented reports",
          description: "Efficiently manage large subscriber bases with detailed reporting. Paraqum SMS provides application and bandwidth reports, empowering ISPs to make informed decisions and enhance customer satisfaction."
        },
        {
          title: "Troubleshooting analytics",
          description: "Utilize advanced analytics for troubleshooting, session monitoring, and real-time network insights. Identify and resolve issues promptly, ensuring a seamless internet experience for subscribers and minimizing disruptions."
        },
        {
          title: "Billing integration",
          description: "Seamlessly integrate with billing systems using Rest API support. Paraqum SMS streamlines billing, allowing ISPs to efficiently manage subscriber accounts and ensure accurate billing for the customized service plans offered."
        },
        {
          title: "Customer Relationship Management",
          description: "Enhance customer relationships with Paraqum SMS's user roles, permissions, and access control features. Provide personalized services, maintain subscriber satisfaction, and differentiate the ISP based on exceptional Quality of Service."
        },
        {
          title: "Network integration",
          description: "Paraqum SMS seamlessly integrates into 3G and 4G mobile networks, enabling ISPs to manage subscriber QoE in oversubscribed cells, BRAS, or CMTS elements. The web-based interface ensures user-friendly control over network attributes."
        }
      ];
      

    const description = "Paraqum Subscriber Management System allows you to identify customer behaviour and create more viable application aware service plans catering to different customer needs. It also allows  efficient customer onboarding and billing.";

    const screens = [
        {
            img: "/assets/screens/subscriberManager/screen1.PNG",
            desc: "",
        },
        {
            img: "/assets/screens/subscriberManager/screen2.PNG",
            desc: "",
        },
        {
            img: "/assets/screens/subscriberManager/screen3.png",
            desc: "",
        }
    ];

    return <ProductTemplate 
        title={title}
        features={features}
        description={description}
        screens={screens}
        slideshow={<Carousel/>}
        watermark={watermark}
        mockup={mockup}
    />
}

export default Product;