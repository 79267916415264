import React from 'react';

const ImageSliderCmp = ({ imageUrls }) => {
  // Ensure there's always enough content to scroll through smoothly
  const doubledImageUrls = Array(4).fill([...imageUrls]).flat(); // Duplicates images multiple times

  return (
    <div className='imageSlider'>
      <div className='slider'>
        <div className='slider-track' style={{ width: `calc(450px * ${doubledImageUrls.length})` }}>
          {doubledImageUrls.map((url, index) => (
            <div className='slide' key={index}>
              <img className='wispa-slider' src={url} alt='event image' />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageSliderCmp;
