import React from 'react';

import Header from '../components/header';
import Footer from '../components/footer';
import Demo from '../components/demo';

const CategoryTemplate = (props) => {
    return <div className={"page"}>
        <div className={"category-template"}>
            <Header/>
            <div className={"category-header"}>
                <div className={"category-banner"}>
                    <img className={"category-banner-mobile"} src={props.bannerm} alt={"banner mobile"} loading="lazy"/>
                    <img className={"category-banner-web"} src={props.banner} alt={"banner"} loading="lazy"/>
                    <div className={"category-container"}>
                        <h1>{props.title}</h1>
                        <p>{props.description}</p>
                    </div>
                </div>
                <div className={"category-icons"}>
                    <img src={props.icons} alt={"icons"} loading="lazy"/>
                </div>
            </div>
            <div className={"category-intro"}>
                <img src={"/assets/categories/pqLogo.svg"} alt={"paraqum logo"} loading="lazy"/>
                <div className={"category-intro-content"}>
                    <div className={"category-intro-content-wrapper"}>
                        {props.intro.intro_title !== null ? <h2>{props.intro.intro_title}</h2> : null}
                        <p>{props.intro.intro_description}</p>
                        {props.intro.intro_link !== null ? <a href={props.intro.intro_link}>{props.intro.intro_link_text}</a> : null}
                    </div>
                </div>
            </div>
            <div className={"category-products"}>
                {
                    props.products.map((product,index)=>{
                        return <div className={"category-product"}>
                            <div className={"category-product-details"}>
                                <div className={"category-product-img"}>
                                    <img src={product.img} alt={product.title} loading={"lazy"}/>
                                </div>
                                <div className={"category-product-text"}>
                                    <div>
                                        <h5>{product.title}</h5>
                                        <p>{product.description}</p>
                                        <a href={product.url}>Learn More</a>
                                    </div>
                                </div>
                            </div>
                            {
                                index < (props.products.length - 1) ? <hr/> : null
                            }
                        </div>
                    })
                }
            </div>
            <Demo/>
            <Footer/>
        </div>
    </div>
}

export default CategoryTemplate;