import React from 'react';

import ProductTemplate from '../../../../templates/productNew';
import Carousel from './carousel';

const Product = () => {
    const title = "Paraqum CeyAnalyst";
    const watermark = "CEYANALYST";
    const mockup = "/assets/screens/ceyAnalyst/DeviceMockup/CeyAnalystMockUp.png"

    const features = [
        {
            title: "Layer 2 to Layer 7 Deep Packet Inspection (DPI) based real-time network analytics",
            description: "Paraqum CeyAnalyst employs DPI for real-time network analytics, offering insights at multiple layers for a comprehensive understanding of network dynamics."
        },
        {
            title: "Zero packet loss and insignificant latency",
            description: "Experience superior network performance with Paraqum CeyAnalyst, ensuring seamless communication and transactions with minimal disruptions."
        },
        {
            title: "Millisecond-level probing",
            description: "Paraqum CeyAnalyst provides millisecond-level probing, allowing precise detection and resolution of network issues for swift problem-solving."
        },
        {
            title: "Alert and report generation",
            description: "Stay informed with Paraqum CeyAnalyst's robust alert and report features. Create alerts based on thresholds and generate detailed usage reports for proactive issue resolution."
        },
        {
            title: "QoS monitoring",
            description: "Ensure optimal Quality of Service (QoS) with Paraqum CeyAnalyst's comprehensive monitoring. Prioritize critical applications, monitor latency, and assess user session quality."
        },
        {
            title: "1G/10G ethernet interfaces with failsafe-enabled design",
            description: "Paraqum CeyAnalyst features 1G/10G Ethernet interfaces with a failsafe design, ensuring reliable and high-speed connectivity for uninterrupted data flow."
        },
        {
            title: "Active database of more than 800 application signatures",
            description: "Access a comprehensive database of over 800 application signatures with Paraqum CeyAnalyst, enhancing network security and management capabilities."
        },
    ];
    
    

    const description = "Paraqum CeyAnalyst gives you real-time visibility at all levels of your network. Closely monitoring application usage, user behavior and network trends while identifying possible issues even before they occur.";

    const screens = [
        {
            img: "/assets/screens/ceyAnalyst/screen1.jpeg",
            desc: "",
        },
        {
            img: "/assets/screens/ceyAnalyst/screen2.jpeg",
            desc: "",
        },
        {
            img: "/assets/screens/ceyAnalyst/screen3.jpeg",
            desc: "",
        }
    ];

    return <ProductTemplate 
        title={title}
        features={features}
        description={description}
        screens={screens}
        slideshow={<Carousel/>}
        mockup={mockup}
        watermark = {watermark}
    />
}

export default Product;