import React, { useState,useRef, useEffect } from 'react';

const featureList = [
    {
        img: "/assets/icons/home/features/logoShaper.svg",
        img_white: "/assets/icons/home/features/logoShaperWhite.svg",
        name: "Traffic Shaper"
    },
    {
        img: "/assets/icons/home/features/logoAnalyzer.svg",
        img_white: "/assets/icons/home/features/logoAnalyzerWhite.svg",
        name: "Network Analyzer"
    },
    {
        img: "/assets/icons/home/features/logoCeyMarshal.svg",
        img_white: "/assets/icons/home/features/logoCeyMarshalWhite.svg",
        name: "CeyMarshal"
    },
    {
        img: "/assets/icons/home/features/logoCeyAnalyst.svg",
        img_white: "/assets/icons/home/features/logoCeyAnalystWhite.svg",
        name: "CeyAnalyst"
    },
    {
        img: "/assets/icons/home/features/logoWiDi.svg",
        img_white: "/assets/icons/home/features/logoWiDiWhite.svg",
        name: "Wi-Di"
    },
    {
        img: "/assets/icons/home/features/logoSMS.svg",
        img_white: "/assets/icons/home/features/logoSMSWhite.svg",
        name: "SMS"
    }
];

const featureMap = [
    {
        product_id: 0,
        id: 1,
        title: "Rate controlling",
        description: "based on maximum and guaranteed bandwidth limitations",
        url: "https://www.youtube.com/embed/5GQcVPYj56U?rel=0"
    },
    {
        product_id: 0,
        id: 2,
        title: "Deep Packet Inspection (DPI) Technology",
        description: "Application-aware traffic shaping based on DPI technology",
        url: "https://www.youtube.com/embed/SCDok6xWOKQ?rel=0"
    },
    {
        product_id: 0,
        id: 3,
        title: "Multi-level shaping",
        description: "Multi-level shaping support for advanced traffic shaping",
        url: "https://www.youtube.com/embed/b4_qHxI3Jow?rel=0"
    },
    {
        product_id: 0,
        id: 4,
        title: "Time/Quota based policy enforcement ",
        description: "One off or periodic schedules with Quota control",
        url: "https://www.youtube.com/embed/uUhmru62UPc?rel=0"
    },
    {
        product_id: 0,
        id: 5,
        title: "URL and Parental Control",
        description: "Global URL and URL category Blacklisting/ Whitelisting",
        url: "https://www.youtube.com/embed/b7ZPt1ksavo?rel=0"
    },

    {
        product_id: 1,
        id: 6,
        title: "QoS monitoring",
        description: "Real-time analytics of the complete network",
        url: "https://www.youtube.com/embed/1O8CczIt4-4?rel=0"
    },
    {
        product_id: 1,
        id: 7,
        title: "Report Generation",
        description: "Bandwidth oriented detailed report generation and comparison",
        url: "https://www.youtube.com/embed/OLjXyCJvPyA?rel=0"
    },
    {
        product_id: 1,
        id: 8,
        title: "Alert Generation",
        description: "Alerts via SMS, Email and Slack",
        url: "https://www.youtube.com/embed/_syGrWyvj0w?rel=0"
    },
    {
        product_id: 1,
        id: 9,
        title: "BGP and GTP protocol support",
        description: "BGP & GTP protocol support required for ISPs and Mobile Operators",
        url: "https://www.youtube.com/embed/iquEoq4RqFQ?rel=0"
    },

    {
        product_id: 2,
        id: 10,
        title: "Real-time bandwidth controlling",
        description: "Block/Allow/Shape traffic based on users",
        url: "https://www.youtube.com/embed/4VT-zASxrio?rel=0"
    },
    {
        product_id: 2,
        id: 11,
        title: "Deep Packet Inspection (DPI) Technology",
        description: "Application-aware traffic shaping based on DPI technology",
        url: "https://www.youtube.com/embed/SCDok6xWOKQ?rel=0"
    },
    {
        product_id: 2,
        id: 12,
        title: "Traffic prioritization",
        description: "Trac prioritization per user preference",
        url: "https://www.youtube.com/embed/JtuMgBhR1OQ?rel=0"
    },
    {
        product_id: 2,
        id: 13,
        title: "Time/Quota based bandwidth controlling",
        description: "One off or periodic schedules with Quota control",
        url: "https://www.youtube.com/embed/uUhmru62UPc?rel=0"
    },
    {
        product_id: 2,
        id: 141,
        title: "Priority Levels",
        description: "Traffic Shaping based on priority levels",
        url: "https://www.youtube.com/embed/beGWOA3Lddw?rel=0"
    },

    {
        product_id: 3,
        id: 14,
        title: "Real-time Network Analytics",
        description: "Application level Real-time Analytics",
        url: "https://www.youtube.com/embed/qG33BJncYYE?rel=0"
    },
    {
        product_id: 3,
        id: 15,
        title: "Report Generation",
        description: "Bandwidth oriented detailed report generation and comparison",
        url: "https://www.youtube.com/embed/OLjXyCJvPyA?rel=0"
    },
    {
        product_id: 3,
        id: 16,
        title: "Alert Generation",
        description: "Alerts via SMS, Email and Slack",
        url: "https://www.youtube.com/embed/beGWOA3Lddw?rel=0"
    },
    {
        product_id: 3,
        id: 17,
        title: "Millisecond-level probing",
        description: "One millisecond bandwidth measuring resolution",
        url: "https://www.youtube.com/embed/Qm7MYb3T1RA?rel=0"
    },

    {
        product_id: 4,
        id: 18,
        title: "Bandwidth Controlling",
        description: "Bandwidth optimization with real-time traffic control",
        url: "https://www.youtube.com/embed/HxjDevCzgnY?rel=0"
    },
    {
        product_id: 4,
        id: 19,
        title: "QoE Monitoring",
        description: "Real-time analytics upto subscriber level",
        url: "https://www.youtube.com/embed/HxjDevCzgnY?rel=0"
    },
    {
        product_id: 4,
        id: 20,
        title: "Burst Control",
        description: "Burst Bandwidth limit policies",
        url: "https://www.youtube.com/embed/HxjDevCzgnY?rel=0"
    },
    {
        product_id: 4,
        id: 21,
        title: "Active Queue Management (AQM)",
        description: "Bandwidth Optimization with AQM technique",
        url: "https://www.youtube.com/embed/HxjDevCzgnY?rel=0"
    },
    {
        product_id: 4,
        id: 22,
        title: "SNMP Monitoring",
        description: "SNMP Live network map and device status",
        url: "https://www.youtube.com/embed/HxjDevCzgnY?rel=0"
    },
    {
        product_id: 5,
        id: 23,
        title: "CRM",
        description: "Customer portal that allows customer onboarding and centralized ticket management",
        url: "https://www.youtube.com/embed/HxjDevCzgnY?rel=0"
    },
    {
        product_id: 5,
        id: 24,
        title: "Application aware service plans",
        description: "Application aware service plans",
        url: "https://www.youtube.com/embed/HxjDevCzgnY?rel=0"
    },
    {
        product_id: 5,
        id: 25,
        title: "Subscriber based Data Plans",
        description: "Customised data plans to meet customer requirements",
        url: "https://www.youtube.com/embed/HxjDevCzgnY?rel=0"
    },
    {
        product_id: 5,
        id: 26,
        title: "Subscriber oriented reports",
        description: "Subscriber based detailed reporting",
        url: "https://www.youtube.com/embed/HxjDevCzgnY?rel=0"
    },

];

const Features = (props) => {

    const [selected,setSelected] = useState(0);
    const [feature,setFeature] = useState(null);
    const [features,setFeatures] = useState(featureMap.filter((item)=>item.product_id === 0));
    const featureRef = useRef();
    const selectProduct = (prod) => {
        setSelected(prod);
        let prod_features = featureMap.filter((item)=>item.product_id === prod);
        setFeatures(prod_features);
        setFeature(null);
    }

    useEffect(()=>{
        let selFeature = featureMap.filter((item)=>item.id === props.feature);
        console.log(selFeature);
        if(selFeature.length > 0) {
            setSelected(selFeature[0].product_id);
            let prod_features = featureMap.filter((item)=>item.product_id === selFeature[0].product_id);
            setFeatures(prod_features);
            setFeature(selFeature[0]);
            featureRef.current.scrollIntoView();
        }
    },[props.feature]);

    return <div className={"home-features"} ref={featureRef}>
        <div className={"home-features-content-mobile"}>
            <h3>{featureList[selected].name}</h3>
            <div className="home-features-video">
                {feature !== null ?
                <iframe width="100%" height="100%" src={feature.url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                :
                <iframe width="100%" height="100%" src={"https://www.youtube.com/embed/cIwbPwUJBXY?rel=0"} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>}
            </div>
            <div className="home-features-list">
                <div>
                {
                    features.map((item,index)=>{
                        return <div className={"home-feature-item"} onClick={()=>setFeature(item)}>
                                <h4>{item.title}</h4>
                                <p>{item.description}</p>
                            </div>
                    })
                }
                </div>
            </div>
        </div>
        <div className={"home-features-content-web"}>
            <div className={"home-features-content-web-products"}>
            {
                featureList.map((item, index)=>{
                    return <div className={selected === index ? "home-features-content-web-product selected" : "home-features-content-web-product"} onClick={()=>selectProduct(index)}>
                        {item.name}
                    </div>
                })
            }
            </div>
            <div className={"home-features-content-web-features"}>
                <div className={"home-features-content-web-list"}>
                {
                    features.map((item,index)=>{
                        return <div className={"home-feature-item-container"}>
                                <div className={feature !== null && feature.id === item.id ? "home-feature-item selected" : "home-feature-item"} onClick={()=>setFeature(item)}>
                                    <h4>{item.title}</h4>
                                    <p>{item.description === "" ? <def>&nbsp;</def> :item.description}</p>
                                    <hr/>
                                </div>
                            </div>
                    })
                }
                </div>
                <div className={"home-features-content-web-video"}>
                {feature !== null ?
                <iframe width="100%" height="100%" src={feature.url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                :
                <iframe width="100%" height="100%" src={"https://www.youtube.com/embed/cIwbPwUJBXY?rel=0"} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>}
                </div>
            </div>
        </div>

        <div className={"home-features-sidebar"}>
            {
                featureList.map((item, index)=>{
                    return <SidebarItem item={item} pos={index} select={selectProduct} picked={selected}/>
                })
            }
        </div>
    </div>
}

const SidebarItem = (props) => {
    return <div className={props.picked === props.pos ? "home-feature-product selected" : "home-feature-product"} onClick={()=>props.select(props.pos)}>
        <img src={props.picked === props.pos ? props.item.img : props.item.img_white} alt={props.item.name} loading={"lazy"}/>
    </div>
}

export default Features;