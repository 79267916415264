import React, { useRef, useEffect, useState } from 'react'
import PrivacyPolicy from './'
import PrivacyPolicyTop from './PrivacyPolicyTop'

const Page = () => {

    const divRef = useRef(null);
    const [height, setHeight] = useState(0);
  
    useEffect(() => {
      if (divRef.current) {
        const resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            if (entry.target === divRef.current) {
              setHeight(entry.contentRect.height);
            }
          }
        });
  
        resizeObserver.observe(divRef.current);
  
        // Clean up the observer on component unmount
        return () => {
          resizeObserver.disconnect();
        };
      }
    }, []);


  return (


    <div className="privacyPolicy-container">

<PrivacyPolicyTop title="PRIVACY POLICY" lastUpdate="Last Updated: 17-06-2024"/>


 <div className="privacyPolicy-mid">

     <div className="privacyPolicy-mid-container" ref={divRef}>
        <h2>
        1. Introduction
        </h2>
        <p className="privacyPolicy-content">
        Welcome to Paraqum Technologies ("we," "our," "us"). We are committed to protecting your privacy and ensuring that your personal information is handled safely and responsibly. This Privacy Policy outlines how we collect, use, and disclose your personal information when you visit our website www.paraqum.com.        </p>

        <h2>
        2. Information We Collect
        </h2>
        <p className="privacyPolicy-content">
        We collect various types of information in connection with the services we provide, including:<br/>
        <ul>
        <li><b>Personal Information:</b> Information that can be used to identify you, such as your name, email address, phone number, and payment information. This information is collected when you register on our site, place an order, fill out a form, or interact with our services in other ways.</li><br/>
We never store personal credit/debit or bank account information with us.<br/><br/>
<li><b>Non-Personal Information:</b> Information that cannot be used to identify you personally, such as your browser type, operating system, IP address, and browsing behavior on our website. This information is collected automatically as you interact with our site.</li>
        </ul>

        </p>


        <h2>
        3. How We Use Your Information
        </h2>
        <p className="privacyPolicy-content">
        We use the information we collect for the following purposes:       <ul>
        <li><b>To Provide and Improve Our Services:</b> To personalize your experience, process transactions, and improve our website and services based on feedback we receive from you.</li><br/>
<li><b>To Communicate with You:</b>To send you information and updates related to your orders, respond to inquiries, and send periodic emails with company news, updates, promotions, and related product or service information.</li><br/><br/>
<li><b>To Administer Contests and Surveys:</b>To administer contests, promotions, surveys, or other site features.</li>

        </ul>

        </p>

        <h2>
        4. Data Protection
        </h2>
        <p className="privacyPolicy-content">
        We implement a variety of security measures to maintain the safety of your personal information. These measures include encryption, secure servers, and access controls designed to protect your information from unauthorized access, alteration, disclosure, or destruction.
        </p>

        <h2>
        5. Cookies and Tracking Technologies
        </h2>
        <p className="privacyPolicy-content">
        We use cookies and similar tracking technologies to enhance your experience on our website. Cookies are small files that a site or its service provider transfers to your computer’s hard drive through your web browser (if you allow) that enable the site’s or service provider’s systems to recognize your browser and capture and remember certain information. You can choose to disable cookies through your browser options.       
        </p>

        <h2>
        6. Third-Party Disclosure
        </h2>
        <p className="privacyPolicy-content">
        We <b>NEVER SELL, TRADE,</b> or otherwise <b>TRANSFER</b> your personally identifiable information to any outside party.         
        </p>

        <h2>
        7. Third-Party Links
        </h2>
        <p className="privacyPolicy-content">
        Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked sites.        </p>


        <h2>
        8. Your Consent
        </h2>
        <p className="privacyPolicy-content">
        By using our site, you consent to our privacy policy.
        </p>

        <h2>
        9. Changes to Our Privacy Policy
        </h2>
        <p className="privacyPolicy-content">
        We may update our Privacy Policy from time to time. If we decide to change our privacy policy, we will post those changes on this page, and/or update the Privacy Policy modification date above.        </p>


        <h2>
        10. Contact Us
        </h2>
        <p className="privacyPolicy-content">
        If you have any questions regarding this Privacy Policy, you may contact us at support@paraqum.com</p>

     </div>



 </div>

 <div style={{ height: `${height}px` }} className="termsOfServices-height">
 </div>
        
      
    </div>
  )
}

export default Page
