import React from 'react';

import ProductTemplate from '../../../../templates/productNew';

import Carousel from './carousel';

const Product = () => {
    const title = "Paraqum Ex-Series";
    const watermark = "EX-SERIES";
    const backgroundImageSrc = "/assets/screens/exSeries/BgImage/exseriesBg.jpg";

    const features = [
        {
            title: "Layer 2 to Layer 7 Deep Packet Inspection (DPI) based real-time network analytics",
            description: "Employs DPI technology to provide comprehensive and real-time analysis of network traffic from Layer 2 to Layer 7."
        },
        {
            title: "Detailed report generation",
            description: "Generates detailed reports for in-depth analysis, aiding administrators in understanding network usage and performance."
        },
        {
            title: "Time and quota-based bandwidth controlling",
            description: "Enables precise control over bandwidth usage through flexible time-based policies and quota management."
        },
        {
            title: "Rate controlling based on maximum bandwidth limit",
            description: "Implements rate control mechanisms to ensure that network bandwidth remains within specified maximum limits."
        },
        {
            title: "Application-aware bandwidth controlling",
            description: "Optimizes network performance by intelligently controlling bandwidth based on the characteristics of different applications."
        },
        {
            title: "URL filtering",
            description: "Provides URL filtering capabilities to enhance security and control access to websites, ensuring a secure browsing experience."
        },
        {
            title: "Usage and session-oriented load balancing",
            description: "Facilitates load balancing based on usage patterns and session-oriented criteria, ensuring efficient distribution of network resources."
        },
        {
            title: "Threat prevention-enabled firewall",
            description: "Equipped with a threat prevention firewall to protect the network from malicious activities and enhance overall security."
        },
    ];

    const description = "Paraqum Ex-Series is the perfect solution for any medium scale or small scale business that requires full visibility, control and threat protection over their network link.";

    const screens = [
        {
            img: "/assets/screens/exSeries/screen1.jpeg",
            desc: "",
        },
        {
            img: "/assets/screens/exSeries/screen2.jpeg",
            desc: "",
        },
        {
            img: "/assets/screens/exSeries/screen3.jpeg",
            desc: "",
        }
    ];

    return <ProductTemplate 
        title={title}
        features={features}
        description={description}
        screens={screens}
        slideshow={<Carousel/>}
        backgroundImageSrc = {backgroundImageSrc}
        watermark = {watermark}

    />
}

export default Product;