// Tooltip.js

import React, { useEffect, useState } from 'react';

const Tooltip = ({ text, children, align, size }) => {

    let x =10;
    if (align === 'left') {
      if(size === "small"){
        x = 50;
      }

      if(size === "medium"){
        x = 50;
      }

      if(size === "large"){
        x = 30;
      }

    }

    if (align === 'right') {
      if(size === "small"){
        x = -15;
      }

      if(size === "medium"){
        x = 10;
      }

      if(size === "large"){
        x = 30;
      }

    }

    if (align === 'middle') {
      x = 20;
    }

    console.log("size: " + size);


  return (
    <div className="tooltip-container">
      {children}
      <div className="tooltip-text" style={{left:`${x}%`}}>
        {text}
      </div>
    </div>
  );
};

export default Tooltip;

