import React from "react";
import { Component } from "react";
import BlueBanner from "../../components/blueBanner";
import Footer from '../../components/footer';
import Header from '../../components/header';

class NewsDetails19 extends Component {
  render() {
    return (
      <div className="page">
        {/* <!--Page Header--> */}
        <Header/>
        <BlueBanner text="PARAQUM TECHNOLOGIES LAUNCHES RANGE OF HIGH PERFORMANCE FMC EXPANSION BOARDS" right/>
        {/* <!--Page Header ends --> */}
        {/* <!-- Our Blogs --> */}
        <section  >
                <div class="a-news ">
                  <div class="a-news-image-description">
                    <p>
                      Paraqum Technologies is pleased to announce the launch of
                      its first batch of custom made high-performance FMC
                      expansion boards targeted for FPGA Based Video and
                      Networking Application Development. The two FMC expansion
                      boards released are,
                      <br />
                    </p>
                    <ul>        <br />

                      <li>
                        4 x 10Gbps SFP+ Gigabit Ethernet FMC Expansion Board
                      </li>
                      <li>HDMI 2.0 / DP 1.2 Transmitter FMC Expansion Board</li>
                    </ul>        <br />

                    <h1>4 x 10Gbps SFP+ FMC Expansion Board</h1>
        <br />
                      <img
                        src="/assets/news/news19_1.jpg"
                        alt="4 x 10Gbps SFP+ FMC Expansion Board"
                        loading="lazy"
                      />
                    <br />

                    <p>
                      This FMC Expansion Board features four standard SFP+
                      interfaces, each capable of supporting up to 10Gbps data
                      rate. The standard VITA 57 FMC interface plugs into any
                      compatible FMC HPC slot available on many FPGA development
                      boards. The LVDS lines of the SFP+ interface connect to
                      the high-speed SERDES interfaces of the FPGA device. In
                      addition, there is an on board, I2C configurable, low
                      jitter clock generator IC which can be used as a reference
                      clock source for the SERDES interfaces of the FPGA. The
                      FMC expansion board has been tested with Xilinx Virtex7
                      (VC707), Kintex7 (KC705), Zynq7000 (ZC706) and Artix7
                      (AC701) FPGA development platforms. It is compatible with
                      VADJ of 1.8V, 2.5V and 3.3V.
                    </p>        <br />

                    <h1>HDMI 2.0/DP 1.2 Transmitter FMC Expansion Board</h1>

                    <br />
                      <img
                        src="/assets/news/news19_2.jpg"
                        alt="HDMI 2.0/DP 1.2 Transmitter FMC Expansion Board"
                        loading="lazy"
                      />
 <br />
                    <p>
                      This FMC Expansion Board features an HDMI 2.0 Tx interface
                      and a Displayport 1.2 Tx interface, each capable of
                      supporting up to 4K 60Hz video. The standard VITA 57 FMC
                      interface plugs into any compatible FMC HPC slot available
                      on many FPGA development boards.
                    </p>
                    <p>
                      The LVDS lines of the HDMI/DP interface connect to the
                      high-speed SERDES interfaces of the FPGA device. The board
                      features an LVDS to TMDS converter IC which is used to
                      convert from the LVDS signals of the FPGA SERDES interface
                      to HDMI compatible TMDS signals. In addition, there is an
                      onboard, I2C configurable, low jitter clock generator IC
                      which can be used as a reference clock source for the
                      SERDES interfaces of the FPGA.
                    </p>
                  </div>
                </div>
        </section>
        <br /><br /> <br />
        <Footer/>
      </div>
    );
  }
}

export default NewsDetails19;
