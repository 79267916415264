import React, { Component } from "react";
import Carousel from "react-spring-3d-carousel";
import {v4 as uuidv4} from "uuid";
import { config } from "react-spring";

export default class Screens extends Component {
  state = {
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: true,
    config: config.gentle
  };

  out = [
    {
      key: uuidv4(),
      content: <img src="/assets/screens/wiDi/screen4.jpeg" alt="1" loading="lazy"/>
    },
    {
      key: uuidv4(),
      content: <img src="/assets/screens/wiDi/screen5.jpeg" alt="2" loading="lazy"/>
    },
    {
      key: uuidv4(),
      content: <img src="/assets/screens/wiDi/screen7.jpeg" alt="3" loading="lazy"/>
    }
  ];

  slides = this.out.map((slide, index) => {
    return { ...slide, onClick: () => this.setState({ goToSlide: index }) };
  });

  onChangeInput = e => {
    this.setState({
      [e.target.name]: parseInt(e.target.value, 10) || 0
    });
  };

  render() {
    return (
      <div className="product-screens web">

        <div className="product-screen-title">
            How It Looks
        </div>

        <div className="product-screen-subtitle">
            {
                (this.state.goToSlide === 0) ?
                "Traffic Monitoring"
                : (this.state.goToSlide === 1) ?
                "Alerts"
                : "Multi Level"
            }
        </div>

        <div style={{ width: "80%", height: "500px", margin: "0 auto" }}>
        <Carousel
          slides={this.slides}
          goToSlide={this.state.goToSlide}
          offsetRadius={this.state.offsetRadius}
          showNavigation={this.state.showNavigation}
          animationConfig={this.state.config}
        />
        <div className="cnav-holder">
          <div className="cnav-container">
          {
              this.slides.map((item, index)=>{
                  return <div className="cnav-item" onClick={()=>this.setState({ goToSlide: index })}>
                      {
                          this.state.goToSlide === index ?
                              <img src="/assets/icons/common/isNav.svg" alt="navi-selected" loading="lazy"/>
                              :<img src="/assets/icons/common/notNav.svg" alt="navi-unfocused" loading="lazy"/>
                      }
                  </div>
              })
          }
          </div>
      </div>
      </div>
      </div>
    );
  }
}