import React from 'react';

import CategoryTemplate from '../../templates/category';

const ISPTelco = () => {

    const banner = "/assets/categories/isp_telco/bannerWeb.jpg";

    const bannerm = "/assets/categories/isp_telco/bannerMobile.jpg";

    const icons = "/assets/categories/isp_telco/iconsMobile.svg";

    const title = "ISP/Telco";

    const description = "Visibility and control over your network links to meet daunting customer needs";

    const intro = {
        intro_title: <div>Unparalleled <br />Network Visibility & Control</div>,
        intro_description: "With rapid technological advancements and increased usage of consumer devices, a basic Internet connection no longer meets customer expectations. While network traffic is growing denser, subscribers rely on ISPs and Telcos for a seamless service delivery. May you be a Mobile Operator or a Fixed Broadband Operator, you must be aware of what is going through your network link at all times in order to optimize your service planning. Deliver improved service plans through evaluation of customer patterns and proper bandwidth management. Retain your customer Quality of Experience by offering your subscribers visibility and control over their network link as a VAS. Stand out from the competition.",
        intro_link: null,
        intro_link_text: null
    };

    const products = [
        {
            img: "/assets/categories/isp_telco/networkAnalyzer.svg",
            title: "Network Analyzer",
            description: "Network Traffic Visibility",
            url: "isp_telco/networkAnalyzer"
        },
        {
            img: "/assets/categories/isp_telco/trafficShaper.svg",
            title: "Traffic Shaper",
            description: "Network Intelligence and Control",
            url: "isp_telco/trafficShaper"
        },
        {
            img: "/assets/categories/isp_telco/subscriberManager.svg",
            title: "SMS",
            description: "Subscriber Management System",
            url: "isp_telco/subscriberManager"
        }
    ];

    return <CategoryTemplate bannerm={bannerm} banner={banner} icons={icons} title={title} description={description} intro={intro} products={products}/>
}

export default ISPTelco;