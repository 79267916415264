import React, { useState } from 'react';
import Footer from '../../components/footer';
// import WispamericaTop from './sections/wispamericaTop'
import WhoWeAre from './sections/WhoWeAre'
import Headerwispapalooza from '../../components/headerwispapalooza'
import WhatWeHave from './sections/WhatWeHave'
import WhereYouNeed from '../wispapalooza/sections/WhereYouNeed.jsx';
import WiDiStandOut from './sections/WiDiStandOut'
import MeetUs from './sections/MeetUs'
import Header from '../../components/header.jsx'
import { Helmet } from "react-helmet";
import { WispamericaTop } from './sections/newSections/WispamericaTop.jsx';
import { WidiStandout } from './sections/newSections/WidiStandout.jsx';
import { ImageSlider } from '../wispapalooza/sections/ImageSlider.jsx';
import { WhatWeDo } from './sections/newSections/WhatWeDo.jsx';
window.onbeforeunload = function () {
    window.scrollTo(0, 0);
};

const Wispamerica = () => {
    const [isTransparent, setIsTransparent] = useState(true);

    return <div className={"page"}>
        <Helmet>
            <title>Wispapalooza</title>
            <meta
                name="description"
                content="wispapalooza"
            />
            <meta
                name="keywords"
                content="Traffic shaping,network traffic management,bandwidth management,network traffic analysis,traffic analytics,network traffic control,Network Quality of Experience ,Network QoE,Network QoE Assurance,network traffic optimization,network traffic optimisation,network intelligence,network Quality of Service,network QoS,Session Quality Index,SQI"
            />
        </Helmet>

        <Header/>
        <WispamericaTop />
        <WidiStandout/>
        <WhatWeDo/>
        <div style={{marginTop:'80px', marginBottom:'50px'}}>
        <WhatWeHave /> </div>
        <WhereYouNeed />

        <ImageSlider/>

        
        
        {/* <Headerwispapalooza transparent={isTransparent} />
        <WispamericaTop />
        <WiDiStandOut />
        <WhoWeAre />
        <WhatWeHave />
        <WhereYouNeed />
        <MeetUs /> */}
       
        <Footer />
    </div>
}

export default Wispamerica;