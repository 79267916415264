import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import Timedata from "./Timedata";
const AnyReactComponent = ({ text }) => <div>{text}</div>;
const WhereYouNeed = (props) => {
  const defaultProps = {
    center: {
      lat: 6.87030584773,
      lng: 79.8774397373,
    },
    zoom: 16,
  };

  return (
    <>
      <div className={"wispapalooza-wondering"}>
        <div className="wispapalooza-whoweare-container">
        {/* <div className="wispapaloozaTo-meetus-heading">
        <div className="wispapalooza-whoweare-hr"></div>
          <div className="wispapalooza-whoweare-bold">
          Meet <span className="wispapalooza-whoweare-blue">Us</span> </div> </div> */}
          <div className={"wispapaloozaTo-wondering-column"}>
            <div className="wispapaloozaTo-specialities-heading2">
              
              <div
                className="wispapalooza-whoweare-bold"
                style={{ marginTop: "100px", marginLeft: "100px" }}
              >
                Wondering where we’ll be at the event?
              </div>
              <div
                className="wispapalooza-wondering-body "
                style={{ marginLeft: "100px" }}
              >
                Here’s how to find us. We will be at booth #448.
              </div>


              
              <div class="wrapper-time">
              
                <div className="widi-time-box">
                <Timedata
                    image={"/assets/wispapalooza/time.png"}
                    title={
                      <>
                        <span style={{ whiteSpace: 'nowrap' }}>Monday</span>
                        <br />
                        <span style={{ whiteSpace: 'normal' }}>October 14</span>
                      </>
                    }
                    data1={
                      "07:30 AM To 4:00 PM"
                    }
                    // data2={
                    //   "5.30pm -7.00pm"
                    // }
                  />
                </div>
                <div className="widi-time-box">
                <Timedata
                    image={"/assets/wispapalooza/time.png"}
                    title={
                      <>
                        <span style={{ whiteSpace: 'nowrap' }}>Tuesday</span>
                        <br />
                        <span style={{ whiteSpace: 'normal' }}>October 15</span>
                      </>
                    }
                    data1={
                      "07:30 AM To 6:45 PM"
                    }
                    data2={""}
                  />
                  
                </div>

                <div className="widi-time-box">
                <Timedata
                    image={"/assets/wispapalooza/time.png"}
                    title={
                      <>
                        <span style={{ whiteSpace: 'nowrap' }}>Wednesday</span>
                        <br />
                        <span style={{ whiteSpace: 'normal' }}>October 16</span>
                      </>
                    }
                    data1={
                      "08:00 AM To 9.30 PM"
                    }
                    data2={""}
                  />
                  
                </div>


                <div className="widi-time-box">
                <Timedata
              image={"/assets/wispapalooza/time.png"}
                title={
                  <>
                    <span style={{ whiteSpace: 'nowrap' }}>Thursday</span>
                    <br />
                    <span style={{ whiteSpace: 'normal' }}>October 17</span>
                  </>
                }
            data1={"08:15 AM To 3:45 PM"}
/>

                </div>
                
                </div>
            </div>
            <div className="wispamerica-map">
                <img
                  className="wispamerica-floormap"
                  src={"/assets/events/wispapalooza2024/floorMap2024.png"}
                  alt={"product download left banner"}
                  width={'430px'}
                />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhereYouNeed;
