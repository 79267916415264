import React, { useState } from 'react';
import BlueBanner from '../../components/blueBanner';
import Button from '../../components/button';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { Helmet } from "react-helmet";
import { GlobalLocations } from './globalLocations/GlobalLocations';

const Contact = () => {

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [error, setError] = useState("");

    const host = "https://api.paraqum.com/";

    const submitRequest = () => {
        if (name !== "" && phone !== "" && email !== "" && subject !== "" && description !== "") {
            var headers = new Headers();
            headers.append("Content-Type", "application/json");
            var raw = JSON.stringify({
                "name": name,
                "phone": phone,
                "email": email,
                "subject": subject,
                "description": description
            });
            var requestOptions = {
                method: 'POST',
                headers: headers,
                body: raw,
                redirect: 'follow'
            };

            return new Promise((resolve, reject) => {
                fetch(host + "web/createInquiry", requestOptions)
                    .then(out => out.json())
                    .then(data => {
                        setError("We received your request. We'll get back to you in earliest possible.");
                        setTimeout(() => setError(""), 10000);
                        resolve(data);
                    })
                    .catch(err => {
                        setError("Error Occured");
                        setTimeout(() => setError(""), 3000);
                        reject('Error')
                    })
            })
        } else {
            setError("Please fill the fields to submit the inquiry");
            setTimeout(() => setError(""), 3000);
        }
    }

    return <div className={"page"}>
        <Helmet>
            <title>Contact Us</title>
            <meta
                name="description"
                content="Contact Paraqum"
            />
            <meta
                name="keywords"
                content="Traffic shaping,network traffic management,bandwidth management,network traffic analysis,traffic analytics,network traffic control,Network Quality of Experience ,Network QoE,Network QoE Assurance,network traffic optimization,network traffic optimisation,network intelligence,network Quality of Service,network QoS,Session Quality Index,SQI"
            />
        </Helmet>
        <Header />
        <BlueBanner text={"Contact Us"} />
        <div className={"contact-section"}>
            <div className={"contact-top"}>
                <div className={"contact-half"}>
                    <h2>Got a Question? We are here to help</h2>

                    <div className={"contact-element"}>
                        <div className={"contact-icon"}><img src={"/assets/icons/contact/smartphone.svg"} alt={"phone"} /></div>
                        <div className={"contact-column"}>
                            <h4>Let's Talk</h4>
                            <p><a href="tel:+94112099700">+94 11 2 099700</a>&nbsp; |&nbsp; <a href="tel:+1-469-405-2622">+1-469-405-2622</a></p>
                        </div>
                    </div>

                    <div className={"contact-element"}>
                        <div className={"contact-icon"}><img src={"/assets/icons/contact/email.svg"} alt={"phone"} /></div>
                        <div className={"contact-column"}>
                            <h4>Email Us</h4>
                            <p><a href="mailto:info@paraqum.com">info@paraqum.com</a></p>
                        </div>
                    </div>

                    <div className={"contact-element"}>

                    <span style={{display: 'flex'}}>

                    <div className={"contact-icon"}><img src={"/assets/icons/contact/location.svg"} alt={"phone"} /></div>
                        <div className={"contact-column"}>
                            <h4>Contact Address</h4>
                            <p>Paraqum Technologies, <br />2208 Heather Ridge Dr,<br />Flower Mound,<br />TX 75028,<br />USA.</p>
                        </div>
                        </span>
                        <span style={{marginLeft:'60px',display: 'flex'}}>
                        <div className={"contact-icon"}><img src={"/assets/icons/contact/developmentCenter.svg"} alt={"phone"} /></div>
                        <div className={"contact-column"}>
                            <h4>Development Center</h4>
                            <p>Paraqum Technologies (Pvt) Ltd, <br />106, Bernard Botejue Business Park,<br />Dutugemunu Street,<br />Dehiwala 10350,<br />Sri Lanka.</p>
                        </div>
                        </span>

                        
                    </div>
                </div>
                <div className={"contact-half"}>
                    <iframe className={"pq-map"} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.1684015070073!2d79.87508431482293!3d6.870415320939041!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25a35e4b08c59%3A0x34830edd8acd30f4!2sParaqum%20Technologies!5e0!3m2!1sen!2slk!4v1629278741282!5m2!1sen!2slk" allowfullscreen="" loading="lazy" title="Paraqum Location" style={{ border: 'none' }}></iframe>
                </div>
            </div>

            <GlobalLocations/>

            {/* <div className={"contact-bottom"}>
            <h2 className='contact-regional-partners'>Regional Partners</h2>
            
                <div className={"contact-regions"}>
                    
                    <div className={"contact-partners"}>
                        <h4>Oceania Region</h4>
                        <p>Paraqum Australia<br />234/139 Cardigan Street<br />Melbourne, VIC 3053<br />Australia.<br /><br /><a href="tel:+61391185995">+61 3 9118 5995</a><br /><a href="mailto:info-oceania@paraqum.com"><email>info-oceania@paraqum.com</email></a></p>
                    </div>
                    <div className={"contact-partners"}>
                        <h4>Americas Region</h4>
                        <p>SkyNet Communications LLC.<br />101 E. Mendenhall St. Suite C.<br />Bozeman, MT 59715<br />United States<br /><br /><a href="tel:+14064517104">+1-406-451-7104</a><br /><a href="mailto:info-americas@paraqum.com"><email>info-americas@paraqum.com</email></a></p>
                    </div>
                </div>
                
            </div> */}

            <div className={"contact-form"}>
                    <h2>Write to us</h2>
                    <form>
                        <input type={"text"} name={"name"} placeholder={"Name :"} value={name} onChange={(e) => setName(e.target.value)} />
                        <input type={"phone"} name={"phone"} placeholder={"Phone :"} value={phone} onChange={(e) => setPhone(e.target.value)} />
                        <input type={"email"} name={"email"} placeholder={"Email :"} value={email} onChange={(e) => setEmail(e.target.value)} />
                        <input type={"text"} name={"subject"} placeholder={"Subject :"} value={subject} onChange={(e) => setSubject(e.target.value)} />
                        <textarea placeholder={"Describe your requirements :"} value={description} onChange={(e) => setDescription(e.target.value)} />
                        <div className="send-massage">
                        <Button  text="Send Message" clicked={submitRequest}  />
                        </div>
                        <p>{error}</p>
                    </form>
                </div>


        </div>
        <Footer />
    </div>
}

export default Contact;
