import React, { useState } from "react";
import ToolTip from "../../../templates/components/ToolTip.jsx"
export const GlobalLocations = () => {
  const globalLocations = [
    {
      region: "Head Office",
      address:
      "ParaqumTechnologies Inc./2208 Heather Ridge Dr,/Flower Mound,TX 75028,/ United States",
      phoneNumber: " +1-469-405-2622",
      email: "info@paraqum.com",
    },
    {
      region: "Americas Region",
      address:
      "SkyNet Communications LLC/101 E. Mendenhall St. Suite C./Bozeman, MT 59715 /United States",

      phoneNumber: "+1-406-451-7104",
      email: "info-americas@paraqum.com",
    },
    {
      region: "Oceania  Region",
      address:      "Paraqum Australia,/234/139 Cardigan Street,/Melbourne, VIC 3053 /Australia.",

      phoneNumber: "(03) 9118 5995 / +61 3 9118 5995",
      email: "info-oceania@paraqum.com",
    },
    {
      region: "R&D Office",
      address:
"Paraqum Technologies (Pvt) Ltd.,/106, Bernard Botejue Business Park,/Dutugemunu Street, Dehiwala 10350,/Sri Lanka." ,   
      phoneNumber: "+94 11 2099700/ +94 11 2099800",
      email: "info@paraqum.com",
    },
    
  ];

  return (
    <div className="global-locations">
      <div className="global-locations-container">
        <div className="global-locations-upper">
          <h1>Global Footprint</h1>

          <img
            src="/assets/about/ripple1.svg"
            

           
            className={`global-locations-indicator-srilanka global-locations-indicator `}
       
          />
          <img
             src="/assets/about/ripple1.svg"
            className={`global-locations-indicator-australia global-locations-indicator `}
        
          />
          <img
             src="/assets/about/ripple1.svg"
            className="global-locations-indicator-america global-locations-indicator"

          />
          <img
            src="/assets/about/worldMap.png"
            alt="map"
            className="global-locations-map-sl"
          />
        </div>
      </div>

      <div className="global-location-adresses">
        {globalLocations.map((location, key) => {
          const [company, street,substreet,cityStateZip, country] =
            location.address.split(/(?<!\d)\/(?!\/)/);

          return (
            <div key={key} className="global-location-section">

              <div className="global-location-region">{location.region}</div>

              {company && <p className="global-location-address">{company}</p>}
              {street && <p className="global-location-address">{street}</p>}
              {substreet && <p className="global-location-address">{substreet}</p>}

              {cityStateZip && (
                <p className="global-location-address">{cityStateZip}</p>
              )}
              {country && <p className="global-location-address">{country}</p>}
              <p  className="global-location-email"><a href={`mailto:${location.email}`}>{location.email}</a></p>
              <p className="global-location-phoneNumber"><a href={`tel:${location.phoneNumber}`}>{location.phoneNumber}</a></p>
            </div>
          );
        })}
      </div>

    </div>
  );
};
