import React from 'react';
import BlueBanner from '../components/blueBanner';
import Footer from '../components/footer';
import Header from '../components/header';

const AStudy = (props) => {
    return <div className={"page"}>
        <Header/>
        <BlueBanner text={props.obj.title} right/>
        <div className={"study-container"}>
            <div className={"study-section"}>
                <h2>Area:</h2>
                <def>{
                    props.obj.area.split("||").map((desc)=>{
                        return <p>{desc}</p>
                    })
                }</def>
            </div>
            <div className={"study-section"}>
                <h2>Challenge:</h2>
                <def>{
                    props.obj.challenge.split("||").map((desc)=>{
                        return <p>{desc}</p>
                    })
                }</def>
            </div>
            <div className={"study-section"}>
                <h2>How Paraqum Remedied the issue:</h2>
                <def>{
                    props.obj.how.split("||").map((desc)=>{
                        return <p>{desc}</p>
                    })
                }</def>
            </div>
        </div>
        <Footer/>
    </div>
}

export default AStudy;