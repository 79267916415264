import React from 'react';
// import BlueBanner from '../../components/blueBanner';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { Helmet } from "react-helmet";

const details = {
    "title": "Life at Paraqum",
    "description": "We take pride in our team of highly skilled engineers from top ranking universities around the world. At Paraqum we create a supportive environment for passionate individuals to thrive in their career. Our team with diverse mindsets ensures a balanced work/life inside and outside Paraqum.",
    "images": [
        [
            "/assets/team/team_x5_1.png"
        ],
        [
            "/assets/team/team_x2_1.png",
            "/assets/team/team_x3_1.png"
        ],
        [
            "/assets/team/team_x1_2.png",
            "/assets/team/team_x1_3.png",
            "/assets/team/team_x1_4.png",
            "/assets/team/team_x1_5.png",
            "/assets/team/team_x1_6.png",
        ],
        [
            "/assets/team/team_x3_2.png",
            "/assets/team/team_x2_2.png"
        ],
        [
            "/assets/team/team_x1_7.png",
            "/assets/team/team_x1_8.png",
            "/assets/team/team_x3_3.png"
        ]
    ]
};

const Team = () => {
    return <div className={"page"}>
        <Helmet>
            <title>Our Team</title>
            <meta
                name="description"
                content="Paraqum Team"
            />
            <meta
                name="keywords"
                content="Traffic shaping,network traffic management,bandwidth management,network traffic analysis,traffic analytics,network traffic control,Network Quality of Experience ,Network QoE,Network QoE Assurance,network traffic optimization,network traffic optimisation,network intelligence,network Quality of Service,network QoS,Session Quality Index,SQI"
            />
        </Helmet>
        <Header />
        {/* <BlueBanner text={details.title} /> */}
        <div className='blue-banner team-header'>

            <h4>Life at Paraqum</h4>
            <div className={"team-image-desc"}>
                        <p>{details.description}</p>
                    </div>
        </div>
        <div className={"team"}>


            {/* <div className={"team-image-description"}>
                <div className={"team-image-container"}>
                    <div className={"team-image-desc"}>
                        <p>{details.description}</p>
                    </div>
                   
                    <div className={"team-image-img"}>
                        <img src={"/assets/icons/team/team-header.svg"} alt={"Life at Paraqum"} loading="lazy" />
                    </div>
                </div>
            </div> */}

<div className={"team-image-description"}>
                <div className={"team-image-container"}>
                    
                   
                   
                </div>
            </div>


            <div className={"team-image-wrapper"}>
                {
                    details.images.map((imagerow) => {
                        return <div className={"team-image-row"}>
                            {
                                <div className={"team-image-row-wrapper"}>
                                    {imagerow.map((src, index) => {
                                        return <img src={src} alt={"row image" + index} loading="lazy" />
                                    })}
                                </div>
                            }
                        </div>
                    })
                }
            </div>
        </div>
        <Footer />
    </div>
}

export default Team;