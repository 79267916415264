import React from "react";

const WhatWeHave = (props) => {
  return (
    <>
    {/* <img src={"/assets/wispapalooza/3.png"} alt={"product download left banner"} className={"img3-wispapalooza"} /> */}
      <div className={"wispapalooza-whatwehave"}> 
        <div className={"wispapalooza-container"}>
          <div className="wispapaloozaTo-whatwehave-heading">What we showcase ?</div>
          <div className="wispapaloozaTo-whatwehave-heading2">Glimpse of what we have to offer for you.</div>
          <div class="what-we-have-wrapper">
          <a href={"/wisp/wi-di"} className="what-we-have-box2"><div className="what-we-have-box whatwehave-box1"></div><div className="name">WI-DI</div><img src="/assets/wispapalooza/mobile_file_2022-10-01_11-16-42.png" alt="link" className="nameimage" /></a>
          <a href={"/isp_telco/sms"} className="what-we-have-box2"><div className="what-we-have-box whatwehave-box2"></div><div className="name">SMS</div> <img src="/assets/wispapalooza/mobile_file_2022-10-01_11-16-42.png" alt="link" className="nameimage" /></a>
          <a href={"/wisp/wi-di"} className="what-we-have-box2"><div className="what-we-have-box whatwehave-box3"></div><div className="name">TCP Accelerator</div> <img src="/assets/wispapalooza/mobile_file_2022-10-01_11-16-42.png" alt="link" className="nameimage" /></a>
          <a href={"/isp_telco/networkAnalyzer"} className="what-we-have-box2"><div className="what-we-have-box whatwehave-box4"></div><div className="name">Network Analyzer</div> <img src="/assets/wispapalooza/mobile_file_2022-10-01_11-16-42.png" alt="link" className="nameimage" /></a>
          </div>
        </div>
      </div>
      {/* <div className={"wispapaloozaPolybottom"}></div> */}
    </>
  );
};

export default WhatWeHave;
