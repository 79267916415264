import React from 'react';

import CategoryTemplate from '../../templates/category';
import { Helmet } from "react-helmet";

const SME = () => {

    const bannerm = "/assets/categories/wisp/bannerMobile.jpg";

    const banner = "/assets/categories/wisp/bannerWeb.jpg";

    const icons = "/assets/categories/wisp/iconsMobile.svg";

    const title = "WISP";

    const description = "QoE Monitoring and Bandwidth Optimization";

    const intro = {
        intro_title: <def>Introducing<br />Paraqum Wi-Di</def>,
        intro_description: <def>Are you getting the right ROI while assuring the best QoE for your customers?<br />
            Experience the difference with Paraqum Wi-Di. Gain unprecedented Visibility into your infrastructure and network traffic. Get control of network behaviour that you thought was never possible.
            <br /><br /></def>,
        intro_link: "https://register.paraqum.com",
        intro_link_text: "Request a free trial"
    };

    const products = [
        {
            img: "/assets/categories/wisp/Wi-Di.svg",
            title: "Wi-Di",
            description: "QoE Monitoring and Bandwidth Optimization",
            url: "wisp/wi-di"
        },
        {
            img: "/assets/categories/wisp/Wi-Di-Lite.svg",
            title: "Wi-Di Lite",
            description: "QoE Monitoring and Bandwidth Optimization",
            url: "wisp/wi-di-lite"
        }
    ];

    return <div><Helmet>
        <title>WISP</title>
        <meta
            name="description"
            content="WISP products"
        />
        <meta
            name="keywords"
            content="Traffic shaping, network traffic management, bandwidth management, network traffic analysis, traffic analytics, network traffic control,
      Network Quality of Experience, Network QoE, Network QoE Assurance, network traffic optimization, network intelligence, network Quality of Service, network QoS,
      Active Queue Management, AQM, Smart Queue Management, SQM, network congestion control, Session Quality Index, SQI, burst control, hierarchical shaping,
      Multi-level shaping, multi level shaping, network fair use policy, FUP, FUP enforcement, fair use policy enforcement, guaranteed bandwidth, traffic priority"
        />
    </Helmet><CategoryTemplate bannerm={bannerm} banner={banner} icons={icons} title={title} description={description} intro={intro} products={products} />
    </div>
}

export default SME;