import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  Redirect
} from "react-router-dom";

import Home from './routes/home';

import IspTelco from './routes/isp_telco';
import TrafficShaper from './routes/isp_telco/products/trafficShaper';
import NetworkAnalyzer from './routes/isp_telco/products/networkAnalyzer';
import SubscriberManager from './routes/isp_telco/products/subscriberManager';


import WISP from './routes/wisp';
import WiDi from './routes/wisp/products/wiDi';
import DemoWiDi from './routes/book-a-demo';
import WiDiLiite from './routes/wisp/products/widilite'

import CeySeries from './routes/ceySeries';
import CeyAnalyst from './routes/ceySeries/products/ceyAnalyst';
import CeyMarshal from './routes/ceySeries/products/ceyMarshal';
import CeyFlow from './routes/ceySeries/products/ceyFlow';
import CeyGalaxy from './routes/ceySeries/products/ceyGalaxy';
import CeyBroker from './routes/ceySeries/products/ceyBroker';
import CeyDirect from './routes/ceySeries/products/ceyDirectI';


import SME from './routes/sme';
import ExSeries from './routes/sme/products/exSeries';

import Contact from "./routes/contact";
import About from "./routes/about";
import News from "./routes/news";
import CaseStudies from "./routes/caseStudies";
import UseCases from './routes/useCases';

import AStudy from './templates/astudy';
import StudyList from "./studies.json";
import ANews from "./templates/anews";
import NewsDetails19 from "./routes/news/news-details-19";

import NewsList from "./news_content.json";
import Team from "./routes/team";
import AUse from "./templates/ause";
import UsecaseList from "./usecases.json";
import DataSheets from "./routes/dataSheets";

import Events from "./routes/events";
import EventItem from "./routes/events/eventItem";
import Wispapalooza from "./routes/wispapalooza";
import Wispamerica from "./routes/wispamerica";
import DemoWidiLite from "./routes/book-a-widi-lite";
import { Helmet } from "react-helmet";
import PrivacyPolicy from "./routes/privacy_policy";
import TermsOfServices from "./routes/TermsOfServices";

export default function App() {
  return (
    <Router>
      <Helmet>
        <title>Paraqum Technologies</title>
        <meta
          name="description"
          content="Paraqum Technologies is one of the emerging network product manufacturers that specializes in network intelligence and control."
        />
        <meta
          name="keywords"
          content="Traffic shaping,network traffic management,bandwidth management,network traffic analysis,traffic analytics,network traffic control,Network Quality of Experience ,Network QoE,Network QoE Assurance,network traffic optimization,network traffic optimisation,network intelligence,network Quality of Service,network QoS,Session Quality Index,SQI"
        />
      </Helmet>
      <Switch>
        <Route path='/emds' component={() => {
            window.location.href = 'https://designservices.paraqum.com/';
            return null;
        }}/>
        <Route exact path="/widi-lite/register">
          <DemoWidiLite />
        </Route>
        <Route exact path="/wispapalooza">
          <Redirect to="/events" />
        </Route>
        <Route exact path="/wispamerica">
          <Redirect to="/events" />
        </Route>
        <Route exact path="/wispapalooza2024">
          <Wispamerica />
        </Route>
        <Route exact path="/isp_telco">
          <IspTelcoProducts />
        </Route>
        <Route path="/isp_telco/:product" children={<IspTelcoProducts />} />
        <Route exact path="/wisp">
          <WISP />
        </Route>
        <Route path="/wisp/:product" children={<WISPProducts />} />
        <Route exact path="/ceySeries">
          <CeySeriesProducts />
        </Route>
        <Route path="/ceySeries/:product" children={<CeySeriesProducts />} />
        <Route exact path="/sme">
          <SMEProducts />
        </Route>
        <Route path="/sme/:product" children={<SMEProducts />} />
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route exact path="/book-a-demo">
          <DemoWiDi />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/news">
          <News />
        </Route>
        <Route path="/news/:newsItem" children={<NewsItems />} />
        <Route exact path="/team">
          <Team />
        </Route>
        <Route exact path="/case-studies">
          <CaseStudies />
        </Route>
        <Route path="/case-studies/:casestudy" children={<CaseStudiesCases />} />
        <Route exact path="/use-cases">
          <UseCases />
        </Route>
        <Route path="/use-cases/:usecase" children={<UsecasesItems />} />


        <Route exact path="/events">
          <Events/>
        </Route>
        <Route path="/events/:eventId" component={EventItem} />

        <Route exact path="/Privacy-Policy">
        <PrivacyPolicy/>
        </Route>

        <Route exact path="/Terms-of-Services">
        <TermsOfServices/>
        </Route>


        <Route exact path="/datasheets">
          <DataSheets />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

function WISPProducts() {
  let { product } = useParams();
  console.log("PRODUCT", product)
  return (
    <div>
      <Helmet>
        <title>WISP Products</title>
        <meta
          name="description"
          content="WISP products"
        />
        <meta
          name="keywords"
          content="Traffic shaping,network traffic management,bandwidth management,network traffic analysis,traffic analytics,network traffic control,Network Quality of Experience ,Network QoE,Network QoE Assurance,network traffic optimization,network traffic optimisation,network intelligence,network Quality of Service,network QoS,Session Quality Index,SQI"
        />
      </Helmet>
      {
        product === "wi-di" ?
          <WiDi />
          : product === "wi-di-lite" ? <WiDiLiite />
            : <WISP />
      }
    </div>
  );
}

function IspTelcoProducts() {
  let { product } = useParams();
  console.log("PRODUCT", product);
  return (
    <div>
      <Helmet>
        <title>ISP Telco Products</title>
        <meta
          name="description"
          content="ISP Telco products"
        />
        <meta
          name="keywords"
          content="Traffic shaping,network traffic management,bandwidth management,network traffic analysis,traffic analytics,network traffic control,Network Quality of Experience ,Network QoE,Network QoE Assurance,network traffic optimization,network traffic optimisation,network intelligence,network Quality of Service,network QoS,Session Quality Index,SQI"
        />
      </Helmet>
      {
        product === "trafficShaper" ?
          <TrafficShaper />
          : product === "networkAnalyzer" ?
            <NetworkAnalyzer />
            : product === "sms" ?
              <SubscriberManager />
              : <IspTelco />
      }
    </div>
  );
}

function CeySeriesProducts() {
  let { product } = useParams();
  console.log("Prod", product);
  return (
    <div>
      <Helmet>
        <title>CeySeries Products</title>
        <meta
          name="description"
          content="CeySeries Enterprise products"
        />
        <meta
          name="keywords"
          content="Traffic shaping,network traffic management,bandwidth management,network traffic analysis,traffic analytics,network traffic control,Network Quality of Experience ,Network QoE,Network QoE Assurance,network traffic optimization,network traffic optimisation,network intelligence,network Quality of Service,network QoS,Session Quality Index,SQI"
        />
      </Helmet>
      {
        product === "ceyAnalyst" ?
          <CeyAnalyst />
          : product === "ceyMarshal" ?
            <CeyMarshal />
            : product === "ceyFlow" ?
              <CeyFlow />
              : product === "ceyGalaxy" ?
                <CeyGalaxy />
                : product === "ceyBroker" ?
                  <CeyBroker />
                  : product === "ceyDirectI" ?
                    <CeyDirect />
                    : <CeySeries />
      }
    </div>
  );
}

function SMEProducts() {
  let { product } = useParams();

  return (
    <div>
      <Helmet>
        <title>SME Products</title>
        <meta
          name="description"
          content="Small Medium Enterprise products"
        />
        <meta
          name="keywords"
          content="Traffic shaping,network traffic management,bandwidth management,network traffic analysis,traffic analytics,network traffic control,Network Quality of Experience ,Network QoE,Network QoE Assurance,network traffic optimization,network traffic optimisation,network intelligence,network Quality of Service,network QoS,Session Quality Index,SQI"
        />
      </Helmet>
      {
        product === "ex-series" ?
          <ExSeries />
          : <SME />
      }
    </div>
  );
}

function NewsItems() {
  let { newsItem } = useParams();

  let news = NewsList.filter((item) => item.path === newsItem)
  console.log(NewsList.filter((item) => item.path === newsItem));
  return (
    <div>
      <Helmet>
        <title>News</title>
        <meta
          name="description"
          content="Paraqum News"
        />
        <meta
          name="keywords"
          content="Traffic shaping,network traffic management,bandwidth management,network traffic analysis,traffic analytics,network traffic control,Network Quality of Experience ,Network QoE,Network QoE Assurance,network traffic optimization,network traffic optimisation,network intelligence,network Quality of Service,network QoS,Session Quality Index,SQI"
        />
      </Helmet>
      {newsItem === "paraqum-technologies-launches-range-of-high-performance-fmc-expansion-boards" ?
        <NewsDetails19 />
        : news.length > 0 ?
          <ANews obj={news[0]} />
          : <News />
      }
    </div>
  );
}

function UsecasesItems() {
  let { usecase } = useParams();

  let usecases = UsecaseList.filter((item) => item.path === usecase)
  console.log(usecases);

  return (
    <div>
      <Helmet>
        <title>Usecases</title>
        <meta
          name="description"
          content="Paraqum usecases"
        />
        <meta
          name="keywords"
          content="Traffic shaping,network traffic management,bandwidth management,network traffic analysis,traffic analytics,network traffic control,Network Quality of Experience ,Network QoE,Network QoE Assurance,network traffic optimization,network traffic optimisation,network intelligence,network Quality of Service,network QoS,Session Quality Index,SQI"
        />
      </Helmet>
      {
        usecases.length > 0 ?
          <AUse obj={usecases[0]} />
          : <UseCases />
      }
    </div>
  );
}

function CaseStudiesCases() {
  let { casestudy } = useParams();

  let studies = StudyList.filter((item) => item.path === casestudy)

  return (
    <div>
      <Helmet>
        <title>Case Studies</title>
        <meta
          name="description"
          content="Paraqum Case Studies"
        />
        <meta
          name="keywords"
          content="Traffic shaping,network traffic management,bandwidth management,network traffic analysis,traffic analytics,network traffic control,Network Quality of Experience ,Network QoE,Network QoE Assurance,network traffic optimization,network traffic optimisation,network intelligence,network Quality of Service,network QoS,Session Quality Index,SQI"
        />
      </Helmet>
      {
        studies.length > 0 ?
          <AStudy obj={studies[0]} />
          : <CaseStudies />
      }
    </div>
  );
}

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
};