import React from 'react';

import ProductTemplate from '../../../../templates/productNew';

import Carousel from './carousel';

const Product = () => {
    const title = "Paraqum CeyBroker";
    const watermark = "CEYBROKER";
    const mockup = "/assets/screens/ceyBroker/DeviceMockup/CeyBrokerMockup.png"
    const features = [
        {
            title: "Aggregation of traffic from multiple TAPs and SPAN ports",
            description: "Paraqum CeyBroker efficiently aggregates traffic from multiple TAPs (Test Access Points) and SPAN (Switched Port Analyzer) ports, providing comprehensive visibility into network activities."
        },
        {
            title: "Deduplication of redundant packets",
            description: "Ensure network efficiency and optimize resource utilization with Paraqum CeyBroker's deduplication capability, eliminating redundant packets and minimizing unnecessary data traffic."
        },
        {
            title: "Zero packet loss architecture",
            description: "Experience a robust and reliable network performance with Paraqum CeyBroker's zero packet loss architecture. Maintain continuous data flow without any loss, ensuring the highest level of service quality."
        },
        {
            title: "Packet forwarding to multiple tools simultaneously",
            description: "Paraqum CeyBroker facilitates the forwarding of packets to multiple tools simultaneously, enhancing the flexibility and efficiency of network monitoring and analysis processes."
        },
        {
            title: "Multiple filter rules for ports",
            description: "Customize and control the traffic flow with Paraqum CeyBroker's support for multiple filter rules for ports. Define specific filtering criteria to meet the unique requirements of your network environment."
        },
        {
            title: "Traffic management through advanced filtering up to Layer 4",
            description: "Implement advanced traffic management with Paraqum CeyBroker's filtering capabilities extending up to Layer 4. Effectively manage network traffic based on MAC Address, VLAN, Ethertype, IP Protocol, IPv4 & IPv6 Address, Session, Port, and TCP Control."
        },
        {
            title: "TCP level event detection",
            description: "Detect and respond to TCP-level events promptly with Paraqum CeyBroker. Receive real-time alerts for missing, duplicate, and out-of-sequence packets, enabling proactive monitoring and issue resolution."
        },
        {
            title: "Protocol header stripping",
            description: "Paraqum CeyBroker supports protocol header stripping, allowing for on-device use. Customize and optimize packet data by removing unnecessary headers for specific applications or services."
        },
        {
            title: "PTP and NTP time stamping",
            description: "Ensure accurate and synchronized time stamping at the nanosecond level with Paraqum CeyBroker's support for Precision Time Protocol (PTP) and Network Time Protocol (NTP). Maintain high-precision time references for effective network analysis."
        },
    ];
    

    const description = "Paraqum CeyBroker capture, aggregate, filter and redirect traffic coming in from multiple ports based on packet data information and  different service requirements. It enables improved security and agility for complex networks that requires precise monitoring and control.";

    const screens = [
        {
            img: "/assets/screens/ceyBroker/screen1.jpeg",
            desc: "",
        },
        {
            img: "/assets/screens/ceyBroker/screen2.jpeg",
            desc: "",
        },
        {
            img: "/assets/screens/ceyBroker/screen3.jpeg",
            desc: "",
        }
    ];

    return <ProductTemplate 
        title={title}
        features={features}
        description={description}
        screens={screens}
        slideshow={<Carousel/>}
        mockup={mockup}
        watermark = {watermark}
    />
}

export default Product;