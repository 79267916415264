import React, { useState, useEffect, useRef } from 'react';
import Card from './Card';

const CardCarousel = ({ items }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [itemsPerView, setItemsPerView] = useState(3);
    const carouselRef = useRef(null);
    const touchStartX = useRef(0);
    const touchEndX = useRef(0);

    useEffect(() => {
        const updateItemsPerView = () => {
            if (window.innerWidth < 768) {
                setItemsPerView(1);
            } else if (window.innerWidth < 1300){
                setItemsPerView(2);
            } else {
                setItemsPerView(3);
            }
        };

        updateItemsPerView(); 
        window.addEventListener('resize', updateItemsPerView); 

        return () => window.removeEventListener('resize', updateItemsPerView); 
    }, []);

    const nextItem = () => {
        setCurrentIndex(prevIndex => Math.min(prevIndex + 1, items.length - itemsPerView));
    };

    const prevItem = () => {
        setCurrentIndex(prevIndex => Math.max(prevIndex - 1, 0));
    };

    useEffect(() => {
        const node = carouselRef.current;
        if (node) {
            node.style.transition = 'transform 0.5s ease-out';
            node.style.transform = `translateX(-${currentIndex * (100 / itemsPerView)}%)`;
        }
    }, [currentIndex, itemsPerView]);

    const handleTouchStart = (e) => {
        touchStartX.current = e.touches[0].clientX;
    };

    const handleTouchMove = (e) => {
        touchEndX.current = e.touches[0].clientX;
    };

    const handleTouchEnd = () => {
        if (touchStartX.current - touchEndX.current > 50) {
            nextItem();
        } else if (touchStartX.current - touchEndX.current < -50) {
            prevItem();
        }
    };

    const handleWheel = (e) => {
        if (e.deltaX > 0) {
            nextItem();
        } else if (e.deltaX < 0) {
            prevItem();
        }
    };

    return (
        <div className="events-carousel-container">
            <img src='/assets/events/slideLeftButton.svg' onClick={prevItem} className={`events-carousel-btn events-left-btn ${currentIndex === 0 ? 'events-hidden' : ''}`}/>
            <div className="events-carousel-wrapper" ref={carouselRef}
                 onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}
                 onWheel={handleWheel}>
                {items.map((item, index) => (
                    <div className="events-carousel-card" key={index}>
                        <Card item={item} />
                    </div>
                ))}
            </div>
            <img src='/assets/events/slideButton.svg' onClick={nextItem} className={`events-carousel-btn events-right-btn ${currentIndex === items.length - itemsPerView ? 'events-hidden' : ''}`}/>
        </div>
    );
}

export default CardCarousel;
