import React from 'react';

import ProductTemplate from '../../../../templates/productNew';

import Carousel from './carousel';

const Product = () => {
    const title = "Paraqum CeyFlow";
    const watermark = "CEYFLOW";
    const mockup = "/assets/screens/ceyFlow/DeviceMockup/CyeFlowMockUp.png"

    const features = [
        {
            title: "Line rate packet capturing",
            description: "Paraqum CeyFlow ensures high-performance packet capturing at line rate, allowing real-time analysis and proactive issue detection for superior network management."
        },
        {
            title: "Microburst analysis",
            description: "Efficiently analyze microbursts in network traffic with Paraqum CeyFlow. Identify and address these bursts to optimize network performance and prevent potential disruptions."
        },
        {
            title: "Real-time flow monitoring",
            description: "Monitor network flows in real-time with Paraqum CeyFlow, gaining instant insights into service, source IP, destination IP, source port, destination port, and IP protocol for proactive network management."
        },
        {
            title: "Real-time alert generation for TCP session breaks",
            description: "Receive real-time alerts for TCP session breaks with Paraqum CeyFlow. Stay informed and take immediate remedial actions to ensure continuous and reliable network communication."
        },
        {
            title: "Drill down in detail to analyze TCP session delays, re-transmissions, session break causes, connection attempts, and TCP session lifespan",
            description: "Paraqum CeyFlow offers detailed analysis capabilities, allowing you to drill down into TCP session details. Analyze delays, re-transmissions, session break causes, connection attempts, and TCP session lifespan for thorough network troubleshooting."
        },
        {
            title: "Packet capture analysis on device",
            description: "Conduct packet capture analysis directly on the device with Paraqum CeyFlow. Visualize flow breakdowns, top sources, top service graphs, and more for comprehensive network insights."
        },
        {
            title: "Off-line analysis of captured data for a given duration using tools such as Wireshark",
            description: "Paraqum CeyFlow supports off-line analysis of captured data using tools like Wireshark. Analyze historical data for a specified duration to gain in-depth insights into network behavior."
        },
        {
            title: "Zero packet loss",
            description: "Experience network reliability with zero packet loss, ensuring seamless communication and transactions with Paraqum CeyFlow."
        },
        {
            title: "Syslog integration for external logging of session details",
            description: "Integrate syslog for external logging of session details with Paraqum CeyFlow. Enhance network monitoring and maintain detailed records for analysis and compliance purposes."
        },
        {
            title: "GRE tunneling",
            description: "Paraqum CeyFlow supports GRE tunneling, providing a secure and efficient means of encapsulating network traffic for enhanced communication and data transfer."
        },
    ];
    

    const description = "Paraqum CeyFlow is a network analytic tool which can be used to inspect, analyze and capture packets at line rate for TCP troubleshooting. This captured data can be used for forensic analysis and real-time alert generation for session delays, session drops and much more.";

    const screens = [
        {
            img: "/assets/screens/ceyFlow/screen1.jpeg",
            desc: "",
        },
        {
            img: "/assets/screens/ceyFlow/screen2.jpeg",
            desc: "",
        }
    ];

    return <ProductTemplate 
        title={title}
        features={features}
        description={description}
        screens={screens}
        slideshow={<Carousel/>}
        mockup= {mockup}
        watermark = {watermark}
    />
}

export default Product;