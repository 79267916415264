import React, { useEffect, useLayoutEffect, useState } from "react";
import indicator from "./bannerIndicator1.svg";
const schedule = require("node-schedule");

const Banner = (props) => {
  const [scrollPosition, setPosition] = useState(0);
  const [selected, setSelected] = useState(0);
  const { innerHeight: height } = window;
  let selectedBanner = 0;
  const [banners, setBanners] = useState([]);
  const [banners_mobile, setBanners_mobile] = useState([]);

  useLayoutEffect(() => {
    function updatePosition() {
      setPosition(window.pageYOffset);
      let cur = scrollPosition < height / 2;
      props.setTransparent(cur);
    }
    window.addEventListener("scroll", updatePosition);
    // eslint-disable-next-line
  }, [scrollPosition]);

  useEffect(() => {
    fetch("webBanners.json")
      .then((res) => res.json())
      .then((data) => {
        setBanners(data);
      });
    fetch("mobileBanners.json")
      .then((res) => res.json())
      .then((data) => {
        setBanners_mobile(data);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (banners && banners.length > 0) {
      schedule.scheduleJob("*/5 * * * * *", function () {
        if (selectedBanner + 1 >= banners.length) {
          setSelected(0); // eslint-disable-next-line
          selectedBanner = 0;
        } else {
          selectedBanner++;
          setSelected(selectedBanner);
        }
      });
    }
  }, [banners]);

  return (
    <div className={"home-banner"}>
      {banners.length > 0 && banners_mobile.length > 0 ? (
        <div>
          <div className={"banner-web"}>
            {banners && banners.length > 0 ? (
              <div className={"banner-content"}>
                <div
                  className={
                    banners[selected].textColor === "black"
                      ? "banner-title-black banner-title"
                      : "banner-title"
                  }
                >
                  <h1>{banners[selected].title}</h1>
                </div>
                <div
                  className={
                    banners[selected].textColor === "black"
                      ? "banner-subtitle-black banner-subtitle"
                      : "banner-subtitle"
                  }
                >
                  <p>{banners[selected].description}</p>
                </div>
                <div
                  className={
                    banners[selected]?.cb ? "banner-button cb" : "banner-button"
                  }
                >
                  <a href={banners[selected]?.url}>Learn More</a>
                </div>
                {banners.map((banner, index) => {
                  return (
                    <img
                      src={banners[index].img}
                      alt={"Home Banner"}
                      className={
                        index === selected
                          ? "banner-img selected"
                          : "banner-img"
                      }
                      key={"banner_" + index}
                      loading={"lazy"}
                    />
                  );
                })}
              </div>
            ) : null}
            <div className={"banner-indicator"}>
              {banners.map((banner, index) => {
                return (
                  <div
                    className={
                      index === selected
                        ? "indicator-item selected"
                        : "indicator-item"
                    }
                    onClick={() => {
                      setSelected(index);
                    }}
                    key={"banner_" + index}
                  >
                    {index != selected ? (
                      <img src={indicator} alt={"Navigator"} loading={"lazy"} />
                    ) : (
                      <img
                        src="assets/icons/home/banner/bannerIndicator.svg"
                        alt={"Navigator1"}
                        loading={"lazy"}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={"banner-mobile"}>
            {banners_mobile && banners_mobile.length > 0 ? (
              <div className={"banner-content"}>
                <div className={ banners_mobile[selected].id === 1 ? "banner-title-o" : "banner-title"}>
                  <h1>{banners_mobile[selected].title}</h1>
                </div>
                <div className={"banner-subtitle"}>
                  <p>{banners_mobile[selected].description}</p>
                </div>
                <div
                  className={
                    banners[selected]?.cb ? "banner-button cb" : "banner-button"
                  }
                >
                  {banners_mobile[selected].url != "" && (
                    <a href={banners_mobile[selected].url}>Learn More</a>
                  )}
                </div>
                {banners_mobile.map((banner, index) => {
                  return (
                    <img
                      src={banners_mobile[index].img}
                      alt={"Home Banner"}
                      className={
                        index === selected
                          ? "banner-img selected"
                          : "banner-img"
                      }
                      key={"banner_" + index}
                      loading={"lazy"}
                    />
                  );
                })}
              </div>
            ) : null}
            <div className={"banner-indicator"}>
              {banners_mobile.map((banner, index) => {
                return (
                  <div
                    className={
                      index === selected
                        ? "indicator-item selected"
                        : "indicator-item"
                    }
                    onClick={() => setSelected(index)}
                    key={"banner_" + index}
                  >
                    {index != selected ? (
                      <img src={indicator} alt={"Navigator"} loading={"lazy"} />
                    ) : (
                      <img
                        src="assets/icons/home/banner/bannerIndicator.svg"
                        alt={"Navigator1"}
                        loading={"lazy"}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default Banner;
