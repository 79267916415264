import React from 'react';

import ProductTemplate from '../../../../templates/productNew';

import Carousel from './carousel';

const Product = () => {
    const title = "Paraqum Wi-Di";
    const watermark = "WI-DI";
    const mockup = "/assets/screens/wiDi/DeviceMockup/WidiMockup.png"
    const features = [
        {
          title: "Layer 2 to Layer 7 Deep Packet Inspection (DPI) based real-time network analytics",
          description: "Gain unprecedented insights into your network's performance with Paraqum Wi-Di's advanced DPI technology. Analyze data at multiple layers in real-time for a comprehensive understanding of your network's dynamics."
        },
        {
          title: "Customizable bandwidth reports",
          description: "Tailor bandwidth reports to your specific needs with Paraqum Wi-Di's customizable reporting feature. Easily generate detailed reports that align with your network management goals."
        },
        {
          title: "Millisecond level probing",
          description: "Achieve unparalleled precision in network analysis with Wi-Di's millisecond-level probing. Detect and address network issues with exceptional accuracy and responsiveness."
        },
        {
          title: "Traffic prioritization and bandwidth control",
          description: "Take control of your network's traffic with Paraqum Wi-Di's advanced features. Prioritize critical applications, control data rates, and ensure an optimized bandwidth experience for all users."
        },
        {
          title: "8 priority levels for application classification",
          description: "Paraqum Wi-Di offers a nuanced approach to application classification with 8 priority levels. Tailor your network's performance by assigning different priorities to various applications based on their importance."
        },
        {
          title: "Burst control",
          description: "Mitigate unexpected surges in data traffic with Wi-Di's burst control feature. Ensure network stability and prevent disruptions caused by sudden spikes in data usage."
        },
        {
          title: "Multi-level shaping",
          description: "Fine-tune your network's performance with Paraqum Wi-Di's multi-level shaping capabilities. Shape traffic at different hierarchical levels for a customized and efficient network experience."
        },
        {
          title: "TCP acceleration",
          description: "Elevate your network's efficiency and reduce latency with Paraqum Network Accelerator's transparent TCP acceleration technique."
        },
        {
          title: "SNMP Monitoring",
          description: "Monitor your network health seamlessly with SNMP monitoring provided by Paraqum Wi-Di. Ensure real-time visibility into your network's status for proactive issue resolution and optimal performance."
        },
      ];
      

    const description = "Paraqum Wi-Di is built on top of a DPI and Traffic Shaper engine that works at 100s of Gbps network traffic using state of the art queuing algorithms like CAKE (a mix of AQM and FQ). It gives you actionable insight and unprecedented control over your network traffic to maximise customer QoE, without the burden of additional bandwidth cost.";

    const screens = [
        {
            img: "/assets/screens/wiDi/screen1.jpeg",
            desc: "",
        },
        {
            img: "/assets/screens/wiDi/screen2.jpeg",
            desc: "",
        },
        {
            img: "/assets/screens/wiDi/screen3.jpeg",
            desc: "",
        }
    ];

    return <ProductTemplate 
        title={title}
        features={features}
        description={description}
        screens={screens}
        slideshow={<Carousel/>}
        watermark = {watermark}
        mockup = {mockup}
        terms = {true}
    />
}

export default Product;