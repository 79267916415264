import React from "react";

const Timedata = (props) => {
  return (
    <>
      <div className={"timedata"}>
      <img src={props.image} alt={"product download left banner"} className={"time-image"} />
        <div className="timedata-title">{props.title}</div>
        <div className="timedata-data">{props.data1}<br />{props.data2}</div>
        <div className="timedata-data"></div>
      </div>
    </>
  );
};

export default Timedata;