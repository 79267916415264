import React, { useRef, useEffect, useState } from 'react'
import PrivacyPolicyTop from '../privacy_policy/PrivacyPolicyTop'
import Header from '../../components/header'
import Footer from '../../components/footer'

const TermsOfServices = () => {

  const divRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (divRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.target === divRef.current) {
            setHeight(entry.contentRect.height);
          }
        }
      });

      resizeObserver.observe(divRef.current);

      // Clean up the observer on component unmount
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);


  return (
    <div>

        <Header/>

        <PrivacyPolicyTop title="TERMS OF SERVICES" lastUpdate="Last Updated: 17-06-2024"/>

        <div className="privacyPolicy-mid">

<div className="privacyPolicy-mid-container" ref={divRef}>
    <p className="privacyPolicy-mid-container-qoute"><i>**Refund policies and cancellation policies of ISP customers with contracts are included in the respective contractual agreement.</i></p>
   <h2>
   1. Refund Policy
   </h2>
   <p className="privacyPolicy-content">
   At Paraqum Technologies, we are committed to ensuring customer satisfaction with our products and services. If there is an overpayment for the invoice, you may request a refund within the respective billing month. Approved refunds can be processed in one of two ways: a direct refund to your bank account, where the amount refunded will have any Stripe transaction fees deducted, or by allocating the refund amount to your next billing month without any deductions (i.e. considered as an advance payment for the next month/s). To initiate a refund, please contact our customer support team at support@paraqum.com with your invoice. We will review your request and notify you of the outcome within 2 business days. Thank you for your understanding and cooperation. </p>

   <h2>
   2. Cancellation Policy
   </h2>
   <p className="privacyPolicy-content">
   Our cancellation policy allows you to cancel your subscription or service at any time without any hassle. To cancel, simply contact our customer support team at support@paraqum.com with your account details. Please allow 2 business days for the cancellation to be processed. After the cancellation, the payment will not be refunded and the license will be deactivated at the end of the respective month.
   </p>

</div>

</div>
<div style={{ height: `${height}px` }} className="termsOfServices-height">
    </div>
    <Footer/>
      
    </div>
  )
}

export default TermsOfServices
