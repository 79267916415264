import React, { useEffect } from 'react';

const WidiDemoForm = () => {
  useEffect(() => {
    const loadBitrixFormScript = async () => {
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://cdn.bitrix24.com/b8975275/crm/form/loader_15.js?${Date.now() / 180000 | 0}`;
      const scriptTag = document.getElementsByTagName('script')[0];
      scriptTag.parentNode.insertBefore(script, scriptTag);
    };

    loadBitrixFormScript();
  }, []);

  return (
    <div>
      <script data-b24-form="inline/15/0b562x" data-skip-moving="true"></script>
    </div>
  );
};

export default WidiDemoForm;