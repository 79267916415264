import React, { useEffect, useState } from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Button from '../../components/button';
import Sheets from './sheets/sheets.json';
import { Helmet } from "react-helmet";

const host = "https://api.paraqum.com/";

// const details = {
//     "title": "Datasheets"
// };

const DataSheets = () => {

    const [name, setName] = useState("");
    const [country, setCountry] = useState("");
    const [company, setCompany] = useState("");

    const [confirmed, setConfirmed] = useState(false);

    const [error, setError] = useState("");

    const [filtered, setFiltered] = useState([]);

    const [userKey, setUserKey] = useState(null);

    const downloadCreator = () => {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        var raw = JSON.stringify({ "name": name, "country": country, "company": company, "key": userKey });
        var requestOptions = {
            method: 'POST',
            headers: headers,
            body: raw,
            redirect: 'follow'
        };

        return new Promise((resolve, reject) => {
            fetch(host + "web/createDownloader", requestOptions)
                .then(out => out.json())
                .then(data => {
                    console.log("Creator", data);
                    resolve(data);
                })
                .catch(err => {
                    console.log("ERROR", err);
                    reject('Error')
                })
        })
    }

    const updateDownloader = (item) => {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        var raw = JSON.stringify({ "key": userKey, "product": item.id });
        var requestOptions = {
            method: 'POST',
            headers: headers,
            body: raw,
            redirect: 'follow'
        };

        return new Promise((resolve, reject) => {
            fetch(host + "web/updateDownloader", requestOptions)
                .then(out => out.json())
                .then(data => {
                    console.log("Updator", data);
                    resolve(data);
                })
                .catch(err => {
                    console.log("ERROR", err);
                    reject('Error')
                })
        })
    }

    const checkValid = () => {
        if (name !== "" && country !== "" && company !== "") {
            downloadCreator();
            setConfirmed(true);
            setError("");
        } else {
            setError("Please provide above details to download datasheets");
        }
    }

    useEffect(() => {
        setFiltered(Sheets);
        setUserKey(new Date().getTime());
    }, [])

    return <div className={"page"}>
        <Helmet>
            <title>Datasheets</title>
            <meta
                name="description"
                content="Paraqum Datasheets"
            />
            <meta
                name="keywords"
                content="Traffic shaping,network traffic management,bandwidth management,network traffic analysis,traffic analytics,network traffic control,Network Quality of Experience ,Network QoE,Network QoE Assurance,network traffic optimization,network traffic optimisation,network intelligence,network Quality of Service,network QoS,Session Quality Index,SQI"
            />
        </Helmet>
        <Header/>
        <div className="datasheets-header">
      <div className='datasheets-bgoverlay'>
        <h4>Datasheets</h4>
        <p>See how we helped our customers</p>
        </div>
    </div>
        <div className={"datasheets"}>
            <div className={"datasheets-upper-container"}>
            <div className={"datasheet-container"}>

                    <div className={"datasheet-label-container"}><label>Name</label></div>
                    <div className="datasheet-input-box">
                    <i class="fa fa-id-card-o icon" aria-hidden="true"></i>
                    <input className='datasheet-textbox' type={"text"} onChange={(e) => setName(e.target.value)} value={name} disabled={confirmed} placeholder='Enter your name' />
                    </div>

                    <div className={"datasheet-label-container"}><label>Country</label></div>
                    <div className="datasheet-input-box">
                    <i class="fa fa-map-marker icon" aria-hidden="true"></i>
                    <input className='datasheet-textbox' type={"text"} onChange={(e) => setCountry(e.target.value)} value={country} disabled={confirmed} placeholder='Input Text'/>
                    </div>


                    <div className={"datasheet-label-container"}><label>Company</label></div>
                    <div className="datasheet-input-box">
                    <i class="fa fa-building icon" aria-hidden="true"></i>
                    <input className='datasheet-textbox' type={"text"} onChange={(e) => setCompany(e.target.value)} value={company} disabled={confirmed} placeholder='Enter your company'/>
                    </div>
                </div>
                </div>
            <div className={"datasheet-download"}>
                {
                    confirmed ?
                        <div className={"datasheet-confirmed"}>
                            {
                                filtered.map((sheet) => {
                                    return <div className={"datasheet-downloader"} key={sheet.id}>
                                        <a className={"datasheet-download-wrapper"} href={sheet.doc} download={true} onClick={() => updateDownloader(sheet)}>
                                            <div className={"datasheet-download-item"}>
                                                <img src={sheet.icon} alt={sheet.id} />
                                                <p className={"datasheet-product"}>{sheet.product}</p>
                                                <div className={"datasheet-download-btn"}>
                                                    <p>Download   <i className="fa fa-download"></i></p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                })
                            }
                        </div>
                        : <div className={"datasheet-unconfirmed"}>
                            <div className={"datasheet-error"}>{error}</div>
                            <Button type={"dark"} text={"Confirm Details"} clicked={() => checkValid()} />
                        </div>
                }
            </div>
        </div>
        <Footer />
    </div>
}

export default DataSheets;