import React from 'react';
import BlueBanner from '../components/blueBanner';
import Footer from '../components/footer';
import Header from '../components/header';

const ANews = (props) => {
    return <div className={"page "}>
        <Header/>
        <BlueBanner text={props.obj.title} />
        <div className={"a-news"}>
            <div className={"a-news-image-description"}>
                <p>{
                    props.obj.description.split("||").map((desc)=>{
                        return <p>{desc}</p>
                    })
                }</p>
            </div>
            <div className={"a-news-image-wrapper"}>
            {
                props.obj.images.map((imagerow)=>{
                    return <div className={"a-news-image-row"}>
                        {
                            <div className={"a-news-image-row-wrapper"}>
                            { imagerow.map((src,index)=>{
                                return <img src={src} alt={"row image"+index} loading="lazy"/>
                            }) }
                            </div>
                        }
                    </div>
                })
            }
            </div>
        </div>
        <Footer/>
    </div>
}

export default ANews;