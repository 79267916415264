import React from 'react';

import CategoryTemplate from '../../templates/category';

const SME = () => {

    const banner = "/assets/categories/sme/bannerWeb.jpg";

    const bannerm = "/assets/categories/sme/bannerMobile.jpg";

    const icons = "/assets/categories/sme/iconsMobile.svg";

    const title = "SME";

    const description = "Never let unfettered internet usage or data bundle exhaustion impede your business progression again";

    const intro = {
        intro_title: null,
        intro_description: "In a technology driven world where  internet accessibility is mission critical for day-to-day business operations of all medium and small-scale enterprises, it is challenging to control rapid exhaustion of monthly data quota. As dedicated internet access is beyond the affordable range, many SMEs opt for broadband Internet connections with data capacity limitations. However, Paraqum SME solutions provide a more practical solution to this problem. We give you visibility and control over your network link for better quota management.",
        intro_link: null,
        intro_link_text: null
    };

    const products = [
        {
            img: "/assets/categories/sme/ExSeries.svg",
            title: "Ex-Series",
            description: "Visibility, Control and Threat protection",
            url: "sme/ex-series"
        }
    ];

    return <CategoryTemplate bannerm={bannerm} banner={banner} icons={icons} title={title} description={description} intro={intro} products={products}/>
}

export default SME;