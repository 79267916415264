import React, { useState } from 'react';

import Header from '../../components/header';
import Footer from '../../components/footer';

import { IoIosCalendar } from 'react-icons/io';
import { HiChevronDoubleLeft, HiChevronDoubleRight, HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import BlueBanner from '../../components/blueBanner';
import { Helmet } from "react-helmet";

const newsList = [
    [
        {
            src: "/assets/news/news1_thumbnail.png",
            title: "APRICOT 2020",
            date: "Mar 20, 2020",
            description: "Paraqum attends Asia Pacific’s largest Network conference at Melbourne, Australia as a Silver Sponsor",
            link: "/news/apricot-2020"
        },
        {
            src: "/assets/news/news2_thumbnail.png",
            title: "PARAQUM AT MYANMAR",
            date: "Jan 28, 2020",
            description: "Paraqum showcases the new Enterprise product series at MMNOG Forum 2020  held in Myanmar",
            link: "/news/paraqum-at-myanmar"
        },
        {
            src: "/assets/news/news3_thumbnail.png",
            title: "LKNOG 3",
            date: "Mar 20, 2020",
            description: "“Quality of Service” by our Founder/CEO Dr. Ajith Pasqual",
            link: "/news/lknog-3"
        },
        {
            src: "/assets/news/news4_thumbnail.png",
            title: "APNIC 48 in Thailand",
            date: "OCT 04, 2019",
            description: "Proud Silver Sponsor of APNIC 48 held in Thailand from 5th – 12th September, 2019",
            link: "/news/apnic-48-in-thailand"
        }
    ],
    [
        {
            src: "/assets/news/news5_thumbnail.png",
            title: "PARAQUM at APRICOT 2019 in South Korea",
            date: "MAR 02, 2019",
            description: "Paraqum attends Asia Pacific’s largest Network conference for the 3rd consecutive time as a Silver Sponsor",
            link: "/news/paraqum-at-apricot-2019-in-south-korea"
        },
        {
            src: "/assets/news/news6_thumbnail.png",
            title: "IESA Vision Summit 2019 India",
            date: "FEB 25, 2019",
            description: "Tech talk on \"Building Futuristic Digital Infrastructure\" by our Founder/CEO, Dr. Ajith Pasqual at the IESA Vision Summit 2019, India",
            link: "/news/iesa-vision-summit-2019-india"
        },
        {
            src: "/assets/news/news7_thumbnail.png",
            title: "LkNOG 2",
            date: "NOV 04, 2018",
            description: "“Realizing the true potential of SDN for Service Provider Networks” by our Founder/CEO Dr. Ajith Pasqual",
            link: "/news/lknog-2"
        },
        {
            src: "/assets/news/news8_thumbnail.png",
            title: "APNIC 46 in New Caledonia",
            date: "SEP 17, 2018",
            description: "Paraqum Technologies at Pacific Network Information Centre(APNIC) from 6th to 13th September 2018 in New Caledonia",
            link: "/news/apnic-46-in-new-caledonia"
        }
    ],
    [
        {
            src: "/assets/news/news9_thumbnail.png",
            title: "APRICOT 2018",
            date: "Mar 02, 2018",
            description: "Paraqum Technologies was a proud silver sponsor of APRICOT 2018 held in Nepal from 19th to 28th February 2018",
            link: "/news/apricot-2018"
        },
        {
            src: "/assets/news/news10_thumbnail.png",
            title: "LkNOG 1",
            date: "OCT 25, 2017",
            description: "Paraqum Technologies, proud principal sponsor of Lanka Network Operators Group’s (LkNOG) Inaugural Conference!",
            link: "/news/lknog-1"
        },
        {
            src: "/assets/news/news11_thumbnail.png",
            title: "APNIC 44 in Taiwan",
            date: "SEP 16, 2017",
            description: "Paraqum’s new series of network analytics in Taiwan! APNIC's 44th bi-annual meeting was held from 7th to 14th of September 2017 in Taichung, Taiwan",
            link: "/news/apnic-44-in-taiwan"
        },
        {
            src: "/assets/news/news12_thumbnail.png",
            title: "Paraqum research team is accepted to ASAP 2017",
            date: "JUL 05, 2017",
            description: "A research team from Paraqum in collaboration with the University of Moratuwa was accepted to present the High performance hardware architectures for HVEC",
            link: "/news/paraqum-research-team-is-accepted-to-asap-2017"
        }
    ],
    [
        {
            src: "/assets/news/news13_thumbnail.png",
            title: "Paraqum Technologies at NAB Show",
            date: "APR 20, 2017",
            description: "Paraqum Technologies showcased our latest HEVC solutions at NAB Show 2017 held from 24th to 27th April",
            link: "/news/paraqum-technologies-at-nab-show"
        },
        {
            src: "/assets/news/news14_thumbnail.png",
            title: "Paraqum unveils new logo",
            date: "APR 19, 2017",
            description: "Paraqum Technologies proudly unveiled the new company logo on 19th April 2017 as part of the ongoing evolution of our company’s products & services",
            link: "/news/paraqum-unveils-new-logo"
        },
        {
            src: "/assets/news/news15_thumbnail.png",
            title: "Paraqum unveils real time network analytics product range",
            date: "APR 05, 2017",
            description: "Say hello to our brand new series of Network Solutions! Paraqum Technologies hosted its inaugural product launch to introduce its Real time network analytics products",
            link: "/news/paraqum-unveils-real-time-network-analytics-product-range"
        },
        {
            src: "/assets/news/news16_thumbnail.png",
            title: "Paraqum Technologies at APRICOT 2017",
            date: "MAR 03, 2017",
            description: "APRICOT 2017 in track with APNIC 43, held from 20th February to 2nd March in Ho Chi Minh City, Vietnam",
            link: "/news/paraqum-technologies-at-apricot-2017"
        }
    ],
    [
        {
            src: "/assets/news/news17_thumbnail.png",
            title: "Paraqum Technologies at APNIC42",
            date: "OCT 06, 2016",
            description: "Proud Bronze Sponsor of the 42nd bi-annual meeting of the Asia Pacific Network Information Centre (APNIC 42), Sri Lanka",
            link: "/news/paraqum-technologies-at-apnic42"
        },
        {
            src: "/assets/news/news18_thumbnail.png",
            title: "Disrupt Asia 2016",
            date: "JUL 30, 2016",
            description: "Paraqum at Sri Lanka’s first ever Startup Conference! Introducing our Paragon HEVC decoder and Paraqum Network Solutions at Disrupt Asia 2016 held on 26th and 27th July at The Hilton Colombo",
            link: "/news/disrupt-asia-2016"
        },
        {
            src: "/assets/news/news19_thumbnail.png",
            title: "Paraqum Technologies Launches Range of High Performance FMC Expansion Boards",
            date: "JAN 30, 2016",
            description: "Paraqum Technologies is pleased to announce the launch of its first batch of custom made high performance FMC expansion boards",
            link: "/news/paraqum-technologies-launches-range-of-high-performance-fmc-expansion-boards"
        },
        {
            src: "/assets/news/news20_thumbnail.png",
            title: "Paraqum Technologies Releases 12G SDI Transceiver Interface Board",
            date: "JAN 30, 2016",
            description: "Paraqum Technologies is pleased to announce the release of 12G SDI Transceiver Interface Board targeted for Ultra-HD Video Application Development.",
            link: "/news/paraqum-technologies-is-pleased-to-announce-the-release-of-12g-sdi-transceiver-interface-board-targeted-for-ultra-hd-video-application-development-the-board-features-one-12g-sdi-receiver-interface-and-one-dual-output-12g-sdi-transmitter-interface-and"
        }
    ],
];

const comments = [
    {
        link: "https://echelon.lk/electronic-dreams/?fbclid=IwAR0qWdv-2_dZuMgznQD-myW2AALV9b1PNcGj2f6mOobhHTAyVpZUKjWu1gE",
        src: "/assets/news/reviews/review1.png",
        title: "Electronic Dreams",
        place: "Echeon",
        date: "May 22 2018"
    },
    {
        link: "http://www.readme.lk/paraqum-technologies-network-analytics/?fbclid=IwAR3rcXxJX9fnUUqqLJbJHpIhgUrbryKmxgV06QFlUxrO-LYD9l3ybfJ_shc",
        src: "/assets/news/reviews/review2.jpg",
        title: "Paraqum Technologies: How R...",
        place: "Readme.lk",
        date: "Apr 03, 2017"
    },
    {
        link: "http://www.sundaytimes.lk/161113/business-times/paraqum-technologies-at-forefront-of-electronic-industry-boost-216541.html?fbclid=IwAR0v9J64DOg_6B_lQvEexAWh1CGrjvQBKTZjKFXHrCqpgh2sCF1SIMT1nS8",
        src: "/assets/news/reviews/review3.jpg",
        title: "ParaQum Technologies at fore...",
        place: "The Sunday Times",
        date: "Nov 13, 2016"
    },
    {
        link: "http://www.readme.lk/disrupt-asia-startups-seen/?fbclid=IwAR10m8-N_tsytPnf1J8V8Y25I0fzCiEvJtrAX5Pp3n1IPu5WpP4hkviT2SM",
        src: "/assets/news/reviews/review4.jpg",
        title: "Disrupt Asia 2016 Par...",
        place: "Readme.lk",
        date: "Aug 05, 2016"
    }
]

const News = () => {
    const [selected, setSelected] = useState(0);

    return <div className={"page"}>
        <Helmet>
            <title>News</title>
            <meta
                name="description"
                content="Paraqum News"
            />
            <meta
                name="keywords"
                content="Traffic shaping,network traffic management,bandwidth management,network traffic analysis,traffic analytics,network traffic control,Network Quality of Experience ,Network QoE,Network QoE Assurance,network traffic optimization,network traffic optimisation,network intelligence,network Quality of Service,network QoS,Session Quality Index,SQI"
            />
        </Helmet>
        <Header />
        <BlueBanner text={"Paraqum News"} />
        <div className={"news-section"}>
            <div className={"news-section-left"}>
                {
                    newsList[selected].map((item) => {
                        return <NewsItem obj={item} />
                    })
                }
                <div className={"news-pagination"}>
                    <div className={"news-pagination-item"} onClick={() => setSelected(0)}><HiChevronDoubleLeft /></div>
                    <div className={"news-pagination-item"} onClick={() => { if ((selected - 1) >= 0) setSelected(selected - 1) }}><HiChevronLeft /></div>
                    {
                        newsList.map((item, index) => <div className={selected === index ? "news-pagination-item selected" : "news-pagination-item"} onClick={() => setSelected(index)}>{index + 1}</div>)
                    }
                    <div className={"news-pagination-item"} onClick={() => { if ((selected + 1) < newsList.length) setSelected(selected + 1) }}><HiChevronRight /></div>
                    <div className={"news-pagination-item"} onClick={() => setSelected(newsList.length - 1)}><HiChevronDoubleRight /></div>
                </div>
            </div>
            <div className={"news-section-right"}>
                <div className={"news-comment-wrapper"}>
                    <h3>See what the critics say about us</h3>
                    {
                        comments.map((item, index) => {
                            return <CommentItem obj={comments[index]} />
                        })
                    }
                </div>
                <div className={"news-comment-logo-wrapper"}>
                    <img src={"/assets/icons/common/pq_square.png"} alt={"PQ Logo"} className={"news-comment-logo"} loading="lazy" />
                </div>
            </div>
        </div>
        <Footer />
    </div>
}

const NewsItem = (props) => {
    return <div className={"news-item"}>
        <div className={"news-item-img"}>
            <img src={props.obj.src} alt={"news banner"} loading="lazy" />
        </div>
        <div className={"news-item-details"}>
            <h5>{props.obj.title}</h5>
            <span>{<IoIosCalendar fontSize={'0.9em'} />}&nbsp;{props.obj.date}</span>
            <p>{props.obj.description}</p>
            <a href={props.obj.link}>
                Read More
            </a>
        </div>
    </div>;
}

const CommentItem = (props) => {
    return <a href={props.obj.link} target={"_blank"} rel={"noreferrer"} className={"comment-item-wrapper"}>
        <div className={"comment-item"}>
            <div className={"comment-item-img"}>
                <img src={props.obj.src} alt={"comment banner"} />
            </div>
            <div className={"comment-item-details"}>
                <h5>{props.obj.title}</h5>
                <p>{props.obj.place}</p>
                <p>{props.obj.date}</p>
            </div>
        </div>
    </a>;
}

export default News;