import React from 'react';
import BlueBanner from '../components/blueBanner';
import Footer from '../components/footer';
import Header from '../components/header';

const AUse = (props) => {
    return <div className={"page"}>
        <Header/>
        <BlueBanner text={props.obj.title}/>
        <div className={"a-use-container"}>
            {
                props.obj.content.map((para)=><p>{para}</p>)
            }
        </div>
        <Footer/>
    </div>
}

export default AUse;