import React, { useState } from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Demo from '../../components/demo';
import Button from '../../components/button';
import Studies from '../../studies.json';
import { Helmet } from "react-helmet";

const CaseStudies = () => {

  // const [hover, setHover] = useState(null);

  return <div className={"page"}>
    <Helmet>
      <title>Case Studies</title>
      <meta
        name="description"
        content="Paraqum Case Studies"
      />
      <meta
        name="keywords"
        content="Traffic shaping,network traffic management,bandwidth management,network traffic analysis,traffic analytics,network traffic control,Network Quality of Experience ,Network QoE,Network QoE Assurance,network traffic optimization,network traffic optimisation,network intelligence,network Quality of Service,network QoS,Session Quality Index,SQI"
      />
    </Helmet>
    <Header />
    {/* <BlueBanner text={"Case Studies"} /> */}

    <div className="case-studies-header">
      <div className='case-studies-bgoverlay'>
        <h4>Case Studies</h4>
        <p>See how we helped our customers</p>
        </div>
    </div>

    <div>
      {/* <section id={"case-studies-vector"}>
        <p>See how we helped our customers</p>
        <img src="/assets/icons/case-studies/undraw_Dev_focus_re_6iwt.svg" alt="vector" />
      </section> */}

      {/* <section id={"case-studies-sec"}>
          <div id="case-studies-item">
            <div id="case-studies-title">
              <p>QoE assurance in leased line customer </p>
              <a onClick={() => (window.location.href = "/case-studies/ISP")}>
                More Info
              </a>
            </div>
            <div id="case-studies-img">
              <img
                class="image"
                style={{ height: "90%", width: "90%" }}
                src="https://paraqum.com/images/page-banners/_customerStoryBackground/blur-close-up-device-1597776edt.jpg"
                alt="Leased line customer QoE assurance"
                data-pagespeed-url-hash="1738796372"
              />
            </div>
          </div>
          <div id="case-studies-item">
            <div id="case-studies-title">
              <p>
                Business critical application prioritization in Tech business{" "}
              </p>
              <a onClick={() => (window.location.href = "/case-studies/business-critical-application-prioritization-in-tech-market")}>
                More Info
              </a>
            </div>
            <div id="case-studies-img">
              <img
                class="image"
                style={{ height: "90%", width: "90%" }}
                src="https://paraqum.com/images/page-banners/_customerStoryBackground/blur-close-up-device-1597776edt.jpg"
                alt="Leased line customer QoE assurance"
                data-pagespeed-url-hash="1738796372"
              />
            </div>
          </div>
        </section> */}

        <div className='case-studies-cards'>
        {Studies.map((caseStudy, index) => (
        <CasestudyCards key={index} 
        title={caseStudy.thumb_title} 
        img={caseStudy.thumb_img} 
        link={caseStudy.thumb_link}
        {...caseStudy} />
      ))}
        </div>

      {/* <div className={"case-study-components"}>
        {
          Studies.map((item, index) => <CaseStudyComponent study={item} index={index} hover={hover} onHover={(key) => setHover(key)} />)
        }
      </div> */}

      <Demo />
    </div>
    <Footer />
  </div>
}


const CasestudyCards = ({title,img,link}) => {
  return (
    <div className='casestudy-cards-wrapper'>
      <img src={img} alt='case study image'/>
      <p>{title}</p>
      <a href={link}><button className='btn casestudy-btn'>MORE INFO</button></a>
    </div>
  )
}

const CaseStudyComponent = (props) => {
  return <div className={props.hover === props.index ? "case-study-component hover" : "case-study-component"} onMouseEnter={() => props.onHover(props.index)} onMouseLeave={() => props.onHover(null)}>
    {
      props.index === 0 ? null : <hr />
    }
    <div className={"case-study-content"}>
      <div className={props.hover === props.index ? "case-study-description hover" : "case-study-description"}>
        <p>{props.study.thumb_title}</p>
        <div className={"case-study-btn-container"}>
          <Button type={props.hover === props.index ? "white" : "dark"} text={"More Info"} clicked={() => window.location.href = props.study.thumb_link} />
        </div>
      </div>
      <div className={"case-study-image"}>
        <img src={props.study.thumb_img} alt={props.study.thumb_title} />
      </div>
    </div>
  </div>
}

export default CaseStudies;
