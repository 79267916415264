import React from 'react';

import ProductTemplate from '../../../../templates/productNew';

import Carousel from './carousel';

const Product = () => {
    const title = "Paraqum Network Analyzer";
    const watermark = "NETWORK ANALYZER";
    const mockup = "/assets/screens/networkAnalyzer/NetworkAnalyzerMockup.png"
    const features = [
        {
          title: "Layer 2 to Layer 7 DPI-based real-time analytics",
          description: "Paraqum Network Analyzer provides real-time analytics using Deep Packet Inspection (DPI), offering insights into network behavior from Layer 2 to the Application Layer for effective QoE Assurance."
        },
        {
          title: "Zero packet loss and minimal latency",
          description: "Experience optimal network performance with Paraqum Network Analyzer, ensuring zero packet loss and minimal latency. ISPs can meet growing user demands without compromising on performance."
        },
        {
          title: "Millisecond-level probing",
          description: "Achieve precise network analysis with millisecond-level probing by Paraqum Network Analyzer, ensuring accurate issue detection and responsive solutions for a seamless internet experience."
        },
        {
          title: "Alert and report generation",
          description: "Enhance network management with Paraqum Network Analyzer's alert and report generation. Receive pro-active alerts based on real-time network intelligence and generate detailed reports for ISPs to offer value-added services."
        },
        {
          title: "QoS monitoring",
          description: "Ensure optimal Quality of Service (QoS) for subscribers with Paraqum Network Analyzer. Monitor and enforce QoS parameters, providing a high-quality internet experience and differentiating ISPs based on service quality."
        },
        {
          title: "1G/10G/100G ethernet interfaces with failsafe design",
          description: "Paraqum Network Analyzer supports various ethernet interfaces with a failsafe design. ISPs can smoothly transition with traffic growth, ensuring network stability and reliability even in high-demand scenarios."
        },
        {
          title: "Separate user portal for subscriber Login",
          description: "Facilitate subscriber engagement with Paraqum Network Analyzer's separate user portal. ISPs can offer value-added services, including near real-time application visibility, bandwidth monitoring, live application usage monitoring, and comprehensive reports."
        },
      ];
      
      

    const description = "Paraqum Network Analyzer is a network analytic and report generation tool which delivers real time visibility into your network at millisecond level sensitivity. It can be deployed as a passive probe or as an in-line device.";

    const screens = [
        {
            img: 'assets/screens/networkAnalyzer/screen1.jpg',
            desc: "",
        },
        {
            img: "/assets/screens/networkAnalyzer/screen2.jpeg",
            desc: "",
        },
        {
            img: "/assets/screens/networkAnalyzer/screen3.jpeg",
            desc: "",
        }
    ];

    return <ProductTemplate 
        title={title}
        features={features}
        description={description}
        screens={screens}
        slideshow={<Carousel/>}
        watermark = {watermark}
        mockup = {mockup}
    />
}

export default Product;