import React from "react";
import BlueBanner from "../../components/blueBanner";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Demo from "../../components/demo";
import Button from "../../components/button";
import { Helmet } from "react-helmet";

const UseCases = () => {
  return (
    <div className={"page"}>
      <Helmet>
        <title>Use cases</title>
        <meta name="description" content="Paraqum use cases" />
        <meta
          name="keywords"
          content="Traffic shaping,network traffic management,bandwidth management,network traffic analysis,traffic analytics,network traffic control,Network Quality of Experience ,Network QoE,Network QoE Assurance,network traffic optimization,network traffic optimisation,network intelligence,network Quality of Service,network QoS,Session Quality Index,SQI"
        />
      </Helmet>
      <Header />
      {/* <BlueBanner text={"Use Cases"} /> */}
      <div className="datasheets-header usecase-header">
      <div className='datasheets-bgoverlay'>
        <h4>Use Cases</h4>
        <p>
            Whatever maybe the size of your business, we have the
            <br />
            right solution for you!
          </p>
        </div>
    </div>
      

      <div className="usecase-icon-button-container">
      <a href={"/use-cases/café"}>
        <div className="usecase-icon-button">
          <img
            src={"/assets/icons/use-cases/Cafe.svg"}
            alt={""}
            class={"feature-box-icon"}
            data-pagespeed-url-hash={"2134012399"}
            onload={"pagespeed.CriticalImages.checkImageForCriticality(this);"}
            data-no-retina={""}
          />
          <div className="usecase-name">café</div>
        </div>
        </a>

        <a href={"/use-cases/education"}>
        <div className="usecase-icon-button">
          <img
            src={"/assets/icons/use-cases/Education.svg"}
            alt={""}
            class={"feature-box-icon"}
            data-pagespeed-url-hash={"2134012399"}
            onload={"pagespeed.CriticalImages.checkImageForCriticality(this);"}
            data-no-retina={""}
          />
          <div className="usecase-name">Education</div>
        </div>
        </a>

        <a href={"/use-cases/healthcare"}>
        <div className="usecase-icon-button">
          <img
            src={"/assets/icons/use-cases/Medical.svg"}
            alt={""}
            class={"feature-box-icon"}
            data-pagespeed-url-hash={"2134012399"}
            onload={"pagespeed.CriticalImages.checkImageForCriticality(this);"}
            data-no-retina={""}
          />
          <div className="usecase-name">Healthcare</div>
        </div>
        </a>

 <a href={"/use-cases/enterprise"}>
        <div className="usecase-icon-button">
          <img
            src={"/assets/icons/use-cases/Enterprise.svg"}
            alt={""}
            class={"feature-box-icon"}
            data-pagespeed-url-hash={"2134012399"}
            onload={"pagespeed.CriticalImages.checkImageForCriticality(this);"}
            data-no-retina={""}
          />
          <div className="usecase-name">Enterprise</div>
        </div>
        </a>
        
      </div>

    

      <div className={"usecase-downloads"}>
        <a href={"/assets/useCases/paraqum_usecases.pdf"} download={true}>
          <Button text={"Download All Use Cases"} />
        </a>
      </div>
      <Demo />
      <Footer />
    </div>
  );
};

export default UseCases;
