import React, { useState } from 'react';
import Footer from '../../components/footer';
import WispapaloozaTop from './sections/wispapaloozaTop'
import Specialities from './sections/Specialities'
import WhoWeAre from './sections/WhoWeAre'
import Headerwispapalooza from '../../components/headerwispapalooza'
import WhatWeHave from './sections/WhatWeHave'
import WhereYouNeed from '../wispamerica/sections/WhereYouNeed.jsx'
import WiDiStandOut from './sections/WiDiStandOut'
import MeetUs from './sections/MeetUs'
import Banner from './sections/Banner'
import { ImageSlider } from './sections/ImageSlider';
import { Helmet } from "react-helmet";
window.onbeforeunload = function () {
    window.scrollTo(0, 0);
};

const Wispapalooza = () => {
    const [isTransparent, setIsTransparent] = useState(true);

    return <div className={"page"}>
        <Helmet>
            <title>Wispapalooza</title>
            <meta
                name="description"
                content="Wispapalooza"
            />
            <meta
                name="keywords"
                content="Traffic shaping,network traffic management,bandwidth management,network traffic analysis,traffic analytics,network traffic control,Network Quality of Experience ,Network QoE,Network QoE Assurance,network traffic optimization,network traffic optimisation,network intelligence,network Quality of Service,network QoS,Session Quality Index,SQI"
            />
        </Helmet>
        <Headerwispapalooza transparent={isTransparent} />
        <WispapaloozaTop />
        <WiDiStandOut />
        {/* <Specialities/> */}
        <WhoWeAre />
        <WhatWeHave />
        <WhereYouNeed />
        {/* <MeetUs /> */}
        <ImageSlider/>

        {/* <Banner />  //component has been hidden  */}
        <Footer />
    </div>
}

export default Wispapalooza;