import Header from '../../components/header';
import Footer from '../../components/footer';
import WidiDemoForm from '../../components/widiDemoForm';
import { Helmet } from "react-helmet";

const DemoWidi = () => {

    return <div className={"page"}>
        <Helmet>
            <title>Book a Demo</title>
            <meta
                name="description"
                content="Paraqum Demo"
            />
            <meta
                name="keywords"
                content="Traffic shaping,network traffic management,bandwidth management,network traffic analysis,traffic analytics,network traffic control,Network Quality of Experience ,Network QoE,Network QoE Assurance,network traffic optimization,network traffic optimisation,network intelligence,network Quality of Service,network QoS,Session Quality Index,SQI"
            />
        </Helmet>
        <Header />

        <div className={"demo-header"}>
            <div className={"demo-wrapper"}>
                <h1>Paraqum Wi-Di</h1>
                <p>Paraqum Wi-Di optimizes bandwidth usage by prioritizing network traffic and controlling subscribers and applications. Being equipped with diverse set of unique features and capabilities, Paraqum Wi Di is a prime asset for WISPs to address the growing demand for networks to be intelligently monitored and managed in order to improve subscriber QoE. It is built on top of a DPI and Traffic Shaper engine that works at 100s of Gbps network traffic using state of the art queuing algorithms.</p>
            </div>
        </div>
        <div className={"demo-section"}>
            <div className={"demo-ss-wrapper"}>
                <div class="demo-ss">
                    <img
                        src="assets/demoWidi/image 9.png"
                        alt="4 x 10Gbps SFP+ FMC Expansion Board"
                        loading="lazy"
                    />
                </div>
            </div>
            <div className={"demo-form"}>
                <WidiDemoForm />
            </div>

        </div>
        <div
            className="demoshape" style={{ backgroundImage: "url(assets/demoWidi/image_8.png)", backgroundRepeat: "no-repeat", backgroundSize: " 100% 110%" }}></div>
        <Footer />
    </div>
}

export default DemoWidi;



