import React from 'react'

export const WhatWeDo = () => {
  return (
    <div className='Wispamerica-whatwedo'>
        <div className='Wispamerica-whatwedo-container'>


            <div className='Wispamerica-whatwedo-container-left'>
            <iframe
            className='Wispamerica-video-iframe'
  width="90%"
  height="270px"
  src={"https://www.youtube.com/embed/hgYTzDx_tp0"}
  title="YouTube video player"
  frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen
  style={{ borderRadius: '15px' }} // Set your desired border radius
></iframe>        <h2>What do we do ?</h2>
        <p>If you are struggling to meet the ever so progressive bandwidth demands of your customers and ensuring the Quality of Experience (QoE) yet getting ROI sufficient to sustain the business, we are here to help! Paraqum Wi-Di makes sure that your subscribers enjoy the best out of their networks at the expense of the lowest cost. We aim to optimize network usage and QoE with extremely granular control and visibility.</p>
     
        </div>


        <div className='Wispamerica-whatwedo-container-right'>
           <img src='/assets/screens/wiDi/DeviceMockup/WidiMockup.png'/>
      
           
        </div>

        <div className='Wispamerica-whatwedo-watermark'>
          PRODUCTS
            </div>
    
        </div>

  

       



    </div>
  )
}
