import Header from "../../components/header";
import Footer from "../../components/footer";
import WidiLiteDemoForm from "../../components/widiLiteDemoForm";
import { Helmet } from "react-helmet";

const DemoWidiLite = () => {

  return (
    <div className={"page"}>
      <Helmet>
        <title>WiDi-Lite</title>
        <meta
          name="description"
          content="WiDi-Lite"
        />
        <meta
          name="keywords"
          content="Traffic shaping,network traffic management,bandwidth management,network traffic analysis,traffic analytics,network traffic control,Network Quality of Experience ,Network QoE,Network QoE Assurance,network traffic optimization,network traffic optimisation,network intelligence,network Quality of Service,network QoS,Session Quality Index,SQI"
        />
      </Helmet>
      <Header />

      <div className={"demo-header"}>
        <div className={"demo-wrapper"}>
          <h1>Paraqum WiDi-Lite Registration</h1>
          <p>
            Wi-Di Lite is a basic version of Wi-Di, which is capable of
            accommodating up to 1000 subscribers. It is designed to help WISPs
            improve their QoE by managing their network bandwidth. It helps
            enhance the user experience of internet services by providing
            reliable and fast connectivity with minimal effort while
            accommodating multiple billing system integration. Wi-Di Lite is
            particularly useful for small-scale WISPs that have limited
            resources but still need to optimize their network performance to
            provide efficient services to their subscribers
          </p>
        </div>
      </div>
      <div className={"demo-section"}>
        <div className={"demo-ss-wrapper"}>
          <div class="demo-ss">
            <img
              src="/assets/demoWidiLite/widi_lite_demo.png"
              alt="Widi-lite-screenshots"
              loading="lazy"
            />
          </div>
        </div>
        <div className={"demo-form"}>
          <WidiLiteDemoForm />
        </div>
      </div>
      <div
        className="demoshape"
        style={{
          backgroundImage: "url(assets/demoWidi/image_8.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: " 100% 110%",
        }}
      ></div>
      <Footer />
    </div>
  );
};

export default DemoWidiLite;
