import React from 'react';
import { useParams } from 'react-router-dom';
import eventData from './eventData.json'
import BlueBanner from '../../components/blueBanner';
import Header from '../../components/header';
import Footer from '../../components/footer';
import PreviousEventPage from './PreviousEventPage';


const EventItem = () => {


    const { eventId } = useParams();
    const event = eventData.filter(e => e.id === eventId)[0];

    if (!event) {
        return <div>Event not found</div>;
    }

    return (
        <div className="page">
            <Header />
            <PreviousEventPage/>
            <Footer />
        </div>
    );
};

export default EventItem;
