import React from 'react';

import ProductTemplate from '../../../../templates/productNew';

import Carousel from './carousel';

const Product = () => {
    const title = "Paraqum Wi-Di Lite";
    const watermark = "WI-DI LITE";
    const mockup = "/assets/screens/widiLite/DeviceMockup/WidiLiteMockUp.png"

    const features = [
        {
          title: "Per subscriber real-time traffic visualization (1 ms resolution)",
          description: "Gain precise insights into individual subscriber traffic with Paraqum Wi-Di Lite's real-time visualization, down to 1 ms resolution. Monitor and analyze network dynamics with exceptional precision."
        },
        {
          title: "Subscriber bandwidth controlling policies",
          description: "Take control of bandwidth allocation with Paraqum Wi-Di Lite. Define and enforce customized rules to ensure optimal distribution among subscribers, enhancing overall Quality of Experience (QoE)."
        },
        {
          title: "Uplink and Downlink bandwidth control based on source IP",
          description: "Achieve granular bandwidth control with Paraqum Wi-Di Lite. Manage uplink and downlink bandwidth based on source IP, optimizing network performance and user satisfaction."
        },
        {
          title: "Active Queue Management (AQM) with CAKE algorithm",
          description: "Enhance network performance and mitigate congestion with Paraqum Wi-Di Lite's AQM featuring the CAKE algorithm. Proactively manage queues to ensure efficient data transmission, minimizing delays and disruptions."
        },
        {
          title: "Quality of Service enforcement with dynamic adjustment",
          description: "Elevate the user experience by enforcing Quality of Service (QoS) standards. Paraqum Wi-Di Lite allows dynamic adjustment of guaranteed and maximum bandwidth limits, ensuring a consistent and high-quality network experience."
        },
        {
          title: "Support for multiple billing platforms",
          description: "Simplify billing processes with Paraqum Wi-Di Lite. Seamlessly integrate multiple billing platforms to streamline subscriber management and revenue generation for Wireless Internet Service Providers."
        },
      ];
      


    const description = "Paraqum Wi-Di Lite is the community edition of fully fledged model Paraqum Wi-Di. It is offered free of charge to any WISP having less than 1000 subscribers.";

    const screens = [
        {
            img: "/assets/screens/widiLite/screen1.png",
            desc: "",
        },
        {
            img: "/assets/screens/widiLite/screen2.png",
            desc: "",
        },
        {
            img: "/assets/screens/widiLite/screen3.png",
            desc: "",
        }
    ];

    return <ProductTemplate 
    title={title}
    features={features}
    description={description}
    screens={screens}
    slideshow={<Carousel/>}
    watermark={watermark}
    mockup={mockup}
    />
}

export default Product;