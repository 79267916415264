import React from 'react';

import CategoryTemplate from '../../templates/category';

const CeySeries = () => {

    const banner = "/assets/categories/ceySeries/bannerWeb.jpg";

    const bannerm = "/assets/categories/ceySeries/bannerMobile.jpg";

    const icons = "/assets/categories/ceySeries/iconsMobile.svg";

    const title = "Enterprise (CeySeries)";

    const description = "Be in charge of your network rather than being a spectator";

    const intro = {
        intro_title: null,
        intro_description: <def>
                                How often do you get annoyed by important Skype calls being dropped in a business meeting? Is slow internet disrupting your business, despite an expensive connection? Beware! Bulks of poor customer reviews, black marks on company reputation and even millions of financial losses might be around the corner. It is high time to be in-charge of your own network rather than being a spectator.<br/><br/>
                                See when, where and who is creating congestion in your network. Manage unnecessary applications that are taking up your bandwidth and network resources. Facilitate proactive decision making to reach business goals. Improve QoE by taking control of your network now.
                            </def>,
        intro_link: null,
        intro_link_text: null
    };

    const products = [
        {
            img: "/assets/categories/ceySeries/CeyAnalyst.svg",
            title: "CeyAnalyst",
            description: "Network Traffic Visibility",
            url: "ceySeries/ceyAnalyst"
        },
        {
            img: "/assets/categories/ceySeries/CeyMarshal.svg",
            title: "CeyMarshal",
            description: "Network Intelligence and Control",
            url: "ceySeries/ceyMarshal"
        },
        {
            img: "/assets/categories/ceySeries/CeyFlow.svg",
            title: "CeyFlow",
            description: "TCP troubleshooting and forensic analysis",
            url: "ceySeries/ceyFlow"
        },
        {
            img: "/assets/categories/ceySeries/CeyGalaxy.svg",
            title: "CeyGalaxy",
            description: "Unprecedented control over your branch network",
            url: "ceySeries/ceyGalaxy"
        },
        {
            img: "/assets/categories/ceySeries/CeyDirectI.svg",
            title: <def>CeyDirect-<i>i</i></def>,
            description: "Link & Server load balancing",
            url: "ceySeries/ceyDirect-i"
        },
        {
            img: "/assets/categories/ceySeries/CeyBroker.svg",
            title: "CeyBroker",
            description: "Packet brokering",
            url: "ceySeries/ceyBroker"
        }
    ];

    return <CategoryTemplate bannerm={bannerm} banner={banner} icons={icons} title={title} description={description} intro={intro} products={products}/>
}

export default CeySeries;