import React from 'react';

import ProductTemplate from '../../../../templates/productNew';

import Carousel from './carousel';

const Product = () => {
    const title = <def>Paraqum CeyDirect-<i>i</i></def>;
    const watermark = "CEYDIRECTi";
    const mockup = "/assets/screens/ceyDirectI/DeviceMockup/CeyDirectiMockup.png"


    const features = [
        {
            title: "Application classification based on Deep Packet Inspection (DPI) technology",
            description: "Utilizes DPI technology to accurately classify and identify applications, enabling precise control and optimization of traffic."
        },
        {
            title: "Application-aware static and adaptive load balancing (L3/L7)",
            description: "Intelligently distributes traffic across multiple WAN links based on various criteria, optimizing network performance."
        },
        {
            title: "Bandwidth sharing of individual applications across multiple links",
            description: "Supports bandwidth sharing for efficient utilization of network resources, ensuring seamless user experience for critical applications."
        },
        {
            title: "Advanced traffic scalability and distribution",
            description: "Manages traffic intelligently based on criteria such as lowest latency, least response time, and static restrictions, enhancing network scalability."
        },
        {
            title: "Quality of service enforcement",
            description: "Prioritizes business-critical applications, establishes bandwidth limits, and dynamically adjusts settings for optimal Quality of Experience (QoE)."
        },
        {
            title: "Real-time traffic visualization",
            description: "Provides comprehensive insights into network traffic with 1ms resolution, including detailed statistics and user identification."
        },
        {
            title: "Scheduling and quota management",
            description: "Offers flexible scheduling, time-based policies, and an advanced quota management system for efficient network utilization."
        },
        {
            title: "Advanced link quality and health monitoring",
            description: "Actively monitors links based on factors like latency, response time, and bandwidth, ensuring reliability and health of network links."
        },
        {
            title: "Distributed load balancing support",
            description: "Supports centralized policy creation and management for geographically dispersed networks, enhancing load balancing efficiency."
        },
    ];
    
    

    const description = "Paraqum CeyDirect-i gives you network load balancing and traffic management capability to enhance the reliability of your network while making the best use of your existing infrastructure. Allow complete network link utilization over multiple WAN and Internet links with the best QoE for all applications.";

    const screens = [
        {
            img: "/assets/screens/ceyDirectI/screen1.jpeg",
            desc: "",
        },
        {
            img: "/assets/screens/ceyDirectI/screen2.jpeg",
            desc: "",
        },
        {
            img: "/assets/screens/ceyDirectI/screen3.jpeg",
            desc: "",
        }
    ];

    return <ProductTemplate 
        title={title}
        features={features}
        description={description}
        screens={screens}
        slideshow={<Carousel/>}
        mockup={mockup}
        watermark = {watermark}
    />
}

export default Product;