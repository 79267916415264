import React from 'react'

const PrivacyPolicyTop = ({title,lastUpdate}) => {
  return (
    <div>

<div className="privacyPolicy-top">

<h1>{title}</h1>
<div className="privacyPolicy-updateDate">
<img src="/assets/icons/common/clock.svg" alt="clock" />
<p>{lastUpdate}</p>
</div>

</div>   
    </div>
  )
}

export default PrivacyPolicyTop
