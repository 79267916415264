import React from 'react';

const Categories = () => {
    return <div className={"home-categories"}>
        <div className={"home-title"}>
            <h1>Network Intelligence and Control</h1>
            <p>Whatever the size and shape of your business, we have the right solution for you.</p>
        </div>
        <div className={"home-category-container"}>
            <div className={"home-category"} onClick={()=>{window.location.href = "/wisp"}}>
                <div className={"home-category-icon"}>
                    <img src={"assets/icons/home/categories/iconWISP.svg"} alt={"WISP Logo"} loading={"lazy"}/>
                </div>
                <div className={"home-category-text left"}>
                    <h2>WISP</h2>
                    <p>Make sure you offer the absolute best QOE to your clientele</p>
                </div>
            </div>
            <div className={"home-category mid"} onClick={()=>{window.location.href = "/isp_telco"}}>
                <div className={"home-category-text right"}>
                    <h2>Service Providers</h2>
                    <p>Stand out from your competition with the right kind of Network intelligence and control</p>
                </div>
                <div className={"home-category-icon"}>
                    <img src={"assets/icons/home/categories/iconISP_TELCO.svg"} alt={"Service Provider Logo"} loading={"lazy"}/>
                </div>
            </div>
            <div className={"home-category"} onClick={()=>{window.location.href = "/ceySeries"}}>
                <div className={"home-category-icon"}>
                    <img src={"assets/icons/home/categories/iconEnterprise.svg"} alt={"Enterprise Logo"} loading={"lazy"}/>
                </div>
                <div className={"home-category-text left"}>
                    <h2>Enterprise</h2>
                    <p>Boost the productivity of your organization with the right kind of network solutions</p>
                </div>
            </div>
        </div>
    </div>
}

export default Categories;