import React from 'react'

import image1 from "./Wispa-Event-Images/img1.jpg"
import image2 from "./Wispa-Event-Images/img2.jpg"
import image3 from "./Wispa-Event-Images/img3.jpg"
import image4 from "./Wispa-Event-Images/img4.jpg"
import image5 from "./Wispa-Event-Images/img5.jpg"
import image6 from "./Wispa-Event-Images/img6.jpg"
import image7 from "./Wispa-Event-Images/img7.jpg"
import image8 from "./Wispa-Event-Images/img8.jpg"
import image9 from "./Wispa-Event-Images/img9.jpg"
import image10 from "./Wispa-Event-Images/img10.jpg"
import image11 from "./Wispa-Event-Images/img11.jpg"
import image12 from "./Wispa-Event-Images/img12.jpg"
import image13 from "./Wispa-Event-Images/img13.jpg"
import image14 from "./Wispa-Event-Images/img14.jpg"
import image15 from "./Wispa-Event-Images/img15.jpg"
import image16 from "./Wispa-Event-Images/img16.jpg"
import image17 from "./Wispa-Event-Images/img17.jpg"
import image18 from "./Wispa-Event-Images/img18.jpg"

export const ImageSlider = () => {
  return (
    <div className='imageSlider'>
        <div className='slider'>
            <div className='slider-track'>
                <div className='slide'>
                    <img className='wispa-slider' src={image1} alt='wispapalooza event images'/>
                </div>
                <div className='slide'>
                    <img className='wispa-slider' src={image2} alt='wispapalooza event images'/>
                </div>
                <div className='slide'>
                    <img className='wispa-slider' src={image3} alt='wispapalooza event images'/>
                </div>
                <div className='slide'>
                    <img className='wispa-slider'src={image4} alt='wispapalooza event images'/>
                </div>
                <div className='slide'>
                    <img className='wispa-slider' src={image5} alt='wispapalooza event images'/>
                </div>
                <div className='slide'>
                    <img className='wispa-slider' src={image6} alt='wispapalooza event images'/>
                </div>
                <div className='slide'>
                    <img className='wispa-slider' src={image7} alt='wispapalooza event images'/>
                </div>
                <div className='slide'>
                    <img className='wispa-slider' src={image8} alt='wispapalooza event images'/>
                </div>
                <div className='slide'>
                    <img className='wispa-slider' src={image9} alt='wispapalooza event images'/>
                </div>

                {/* next 9 images */}

                <div className='slide'>
                    <img className='wispa-slider' src={image10} alt='wispapalooza event images'/>
                </div>
                <div className='slide'>
                    <img className='wispa-slider' src={image11} alt='wispapalooza event images'/>
                </div>
                <div className='slide'>
                    <img className='wispa-slider' src={image12} alt='wispapalooza event images'/>
                </div>
                <div className='slide'>
                    <img className='wispa-slider'src={image13} alt='wispapalooza event images'/>
                </div>
                <div className='slide'>
                    <img className='wispa-slider' src={image14} alt='wispapalooza event images'/>
                </div>
                <div className='slide'>
                    <img className='wispa-slider' src={image15} alt='wispapalooza event images'/>
                </div>
                <div className='slide'>
                    <img className='wispa-slider' src={image16} alt='wispapalooza event images'/>
                </div>
                <div className='slide'>
                    <img className='wispa-slider' src={image17} alt='wispapalooza event images'/>
                </div>
                <div className='slide'>
                    <img className='wispa-slider' src={image18} alt='wispapalooza event images'/>
                </div>

            </div>
        </div>
    
    
    </div>
  )
}
