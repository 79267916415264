import React from 'react';
import ProductTemplate from '../../../../templates/productNew';

import Carousel from './carousel';

const Product = () => {
    const title = "Paraqum CeyMarshal";
    const watermark = "CEYMARSHAL";
    const mockup = "/assets/screens/ceyMarshal/DeviceMockup/CeyMarshalMockUp.png"


    const features = [
        {
            title: "Layer 2 to Layer 7 Deep Packet Inspection (DPI) based real-time network analytics",
            description: "Paraqum CeyMarshal employs advanced Deep Packet Inspection (DPI) technology for real-time network analytics. Gain insights into your network's dynamics at multiple layers, ensuring a comprehensive understanding of performance."
        },
        {
            title: "1G/10G ethernet interfaces with failsafe-enabled design",
            description: "Enjoy reliable and high-speed connectivity with Paraqum CeyMarshal's 1G/10G Ethernet interfaces featuring a failsafe-enabled design. Ensure uninterrupted data flow and responsiveness for optimal network performance."
        },
        {
            title: "Traffic prioritization per user preference",
            description: "Take control of your network traffic with Paraqum CeyMarshal. Prioritize applications based on user preferences, ensuring a customized and efficient network experience for all users."
        },
        {
            title: "Rate controlling based on maximum and guaranteed bandwidth limitations",
            description: "Paraqum CeyMarshal allows rate control based on maximum and guaranteed bandwidth limits. Optimize bandwidth usage, prevent congestion, and ensure a consistent Quality of Experience (QoE) for all users."
        },
        {
            title: "Multi-level shaping",
            description: "Fine-tune your network's performance with Paraqum CeyMarshal's multi-level shaping support. Shape traffic at different hierarchical levels for a customized and efficient network experience."
        },
        {
            title: "Time-based bandwidth controlling",
            description: "Paraqum CeyMarshal offers time-based bandwidth controlling with one-off or periodic schedules. Implement different policies for within and outside specified time schedules, ensuring optimal network performance at all times."
        },
        {
            title: "Quota based policy enforcement",
            description: "Efficiently manage network usage with Paraqum CeyMarshal's advanced Quota Management System. Enforce policies based on users, applications, source and destination IPs, services, and URLs. Dynamically adjust quota limits for optimized network utilization."
        },
    ];
    

    const description = "Paraqum CeyMarshal gives you unprecedented control over your own network, allowing you to differentiate between business-critical traffic and recreational traffic. Get the desired utilization of your network through actionable insight and control.";

    const screens = [
        {
            img: "/assets/screens/ceyMarshal/screen1.jpeg",
            desc: "",
        },
        {
            img: "/assets/screens/ceyMarshal/screen2.jpeg",
            desc: "",
        },
        {
            img: "/assets/screens/ceyMarshal/screen3.jpeg",
            desc: "",
        }
    ];

    return <ProductTemplate 
        title={title}
        features={features}
        description={description}
        screens={screens}
        slideshow={<Carousel/>}
        watermark = {watermark}
        mockup = {mockup}
    />
}

export default Product;