import React from 'react';

import ProductTemplate from '../../../../templates/productNew';
import Carousel from './carousel';

const Product = () => {
    const title = "Paraqum CeyGalaxy";
    const watermark = "CEYGALAXY";
    const mockup = "/assets/screens/ceyGalaxy/DeviceMockup/CeyGalaxyMockup.png"

    const features = [
        {
            title: "Layer 2 to Layer 7 Deep Packet Inspection (DPI) based real-time network analytics",
            description: "Paraqum CeyGalaxy employs advanced DPI technology for in-depth analysis of network traffic from Layer 2 to Layer 7, providing real-time insights into the performance and behavior of the entire network infrastructure."
        },
        {
            title: "Analysis of real-time bandwidth and session data usage of individual branches",
            description: "Gain detailed insights into the real-time bandwidth and session data usage of individual branches with Paraqum CeyGalaxy. Monitor and analyze network activities to optimize performance and user experience."
        },
        {
            title: "Geographical analysis and separate detailed report generation of each branch/division",
            description: "Paraqum CeyGalaxy offers geographical analysis, allowing administrators to visualize and compare bandwidth usage and sessions across different branches/divisions. Generate separate detailed reports for comprehensive in-depth analysis."
        },
        {
            title: "Centralized management system for branch environments",
            description: "Efficiently manage branch environments with Paraqum CeyGalaxy's centralized management system. Streamline network administration tasks and ensure consistent policies across branches for enhanced control and optimization."
        },
        {
            title: "Alert generation",
            description: "Paraqum CeyGalaxy provides robust alert generation capabilities. Set thresholds based on various parameters such as uplink/downlink bandwidth, sessions per second, and packets per second. Receive timely alerts through SMS, email, or Slack for proactive network monitoring."
        },
        {
            title: "Traffic prioritization and bandwidth controlling",
            description: "Prioritize traffic and control bandwidth effectively with Paraqum CeyGalaxy. Ensure that business-critical applications receive the necessary resources for optimal performance while enforcing policies to manage overall network bandwidth."
        },
        {
            title: "Time and quota-based policy enforcement",
            description: "Implement time and quota-based policy enforcement with Paraqum CeyGalaxy. Define policies for specific time schedules and quotas, optimizing network usage and preventing unnecessary bandwidth spikes."
        },
        {
            title: "Failsafe-enabled design with passive hardware bypass",
            description: "Ensure network reliability with Paraqum CeyGalaxy's failsafe-enabled design and passive hardware bypass. Minimize disruptions and maintain continuous network functionality even in case of hardware failures."
        },
        {
            title: "High availability cluster for active-active/active-standby configurations",
            description: "Enhance network resilience with Paraqum CeyGalaxy's high availability cluster support. Choose from active-active or active-standby configurations, ensuring uninterrupted network operation and automatic syncing of shaping policies."
        },
    ];
    

    const description = "Paraqum CeyGalaxy is a network analytic and control solution designed for branch environments. It generates detailed branch, link, network oriented reports for in-depth analysis, along with central and distributed control of network traffic in your WAN. Get the desired utilization of your network and maximize the productivity of your branch network by prioritizing business critical applications with no bandwidth upgrades at all.";

    const screens = [
        {
            img: "/assets/screens/ceyGalaxy/screen1.jpeg",
            desc: "",
        },
        {
            img: "/assets/screens/ceyGalaxy/screen2.jpeg",
            desc: "",
        },
        {
            img: "/assets/screens/ceyGalaxy/screen3.jpeg",
            desc: "",
        }
    ];

    return <ProductTemplate 
        title={title}
        features={features}
        description={description}
        screens={screens}
        slideshow={<Carousel/>}
        mockup = {mockup}
        watermark = {watermark}
    />
}

export default Product;