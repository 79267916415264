import React from 'react';
import BlueBanner from '../../components/blueBanner';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { Helmet } from "react-helmet";

const About = () => {

    const stories = [
        "2014/Paraqum Technologies was founded in 2014 as part of an initiative by the Department of Electronic and Telecommunication Engineering, University of Moratuwa as means of expanding the Electronic industry in Sri Lanka.",
        "2014/By December 2014, Paraqum Technologies became the first to demonstrate 4K real-time HEVC decoding on a commercial grade FPGA – breaking the frequency barrier to decode 4K in real-time at less than 150 MHz.",
        "2015/Simultaneously we were developing real-time Network Analytics solutions based on Deep Packet Inspection technology which can analyze high speed network traffic in real-time up to the application level.",
        "2016/In early 2016, we released our first series of Network Analytic products which provide real-time visibility in high-speed networks up to application level. ",
        "2016/This was later followed by the release of the Traffic Shaper product series, which not only provides visibility but also gives the user the ability to exercise unprecedented control over network usage.",
        "2017/With this networking product lineup, Paraqum Technologies is able to provide a complete network management solution to a broader customer segment ranging from small scale businesses to large enterprises as well as Internet Service Providers and Telecom Operators.",
        "Present/By now our product line up has been revamped through integration of advanced features and performance optimization to meet the heightened customer demands!",
    ];

    return <div className={"page"}>
        <Helmet>
            <title>About Us</title>
            <meta
                name="description"
                content="About Paraqum"
            />
            <meta
                name="keywords"
                content="Traffic shaping,network traffic management,bandwidth management,network traffic analysis,traffic analytics,network traffic control,Network Quality of Experience ,Network QoE,Network QoE Assurance,network traffic optimization,network traffic optimisation,network intelligence,network Quality of Service,network QoS,Session Quality Index,SQI"
            />
        </Helmet>
        <Header />
        <BlueBanner text={"Paraqum at a Glance"} />
        <div className={"about-section"}>
            <div className={"about-title top"}>
                <h2>Who we are?</h2>
                <p>Paraqum Technologies is one of the network product manufacturers that specializes in network intelligence and control. In addition to our network products we offer a wide range of high-end electronic design solutions for customer specifications. Driven by our passionate team of individuals, we always bring the latest technology into our products.</p>
            </div>


            {/* <div className={"about-title title"}>
                <h2>Our Specialities</h2>
            </div>
            <div className={"about-button"}>
                <a href={"/"} className={"about-internal"}>
                    Network Solutions
                </a>
                <a href={"https://embedded.paraqum.com"} target={"_blank"} rel={"noreferrer"} className={"about-external"}>
                    Embedded Services&nbsp;<span><svg fill="#FBC21D" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="15px" height="15px"><path d="M 33.40625 0 C 32.855469 0.0507813 32.449219 0.542969 32.5 1.09375 C 32.550781 1.644531 33.042969 2.050781 33.59375 2 L 46.5625 2 L 25.6875 22.90625 C 25.390625 23.148438 25.253906 23.535156 25.339844 23.910156 C 25.425781 24.28125 25.71875 24.574219 26.089844 24.660156 C 26.464844 24.746094 26.851563 24.609375 27.09375 24.3125 L 48 3.4375 L 48 16.40625 C 47.996094 16.765625 48.183594 17.101563 48.496094 17.285156 C 48.808594 17.464844 49.191406 17.464844 49.503906 17.285156 C 49.816406 17.101563 50.003906 16.765625 50 16.40625 L 50 0 L 33.59375 0 C 33.5625 0 33.53125 0 33.5 0 C 33.46875 0 33.4375 0 33.40625 0 Z M 2 10 C 1.476563 10 0.941406 10.183594 0.5625 10.5625 C 0.183594 10.941406 0 11.476563 0 12 L 0 48 C 0 48.523438 0.183594 49.058594 0.5625 49.4375 C 0.941406 49.816406 1.476563 50 2 50 L 38 50 C 38.523438 50 39.058594 49.816406 39.4375 49.4375 C 39.816406 49.058594 40 48.523438 40 48 L 40 18 C 40.003906 17.640625 39.816406 17.304688 39.503906 17.121094 C 39.191406 16.941406 38.808594 16.941406 38.496094 17.121094 C 38.183594 17.304688 37.996094 17.640625 38 18 L 38 48 L 2 48 L 2 12 L 32 12 C 32.359375 12.003906 32.695313 11.816406 32.878906 11.503906 C 33.058594 11.191406 33.058594 10.808594 32.878906 10.496094 C 32.695313 10.183594 32.359375 9.996094 32 10 Z" /></svg></span>
                </a>
            </div> */}

            
            <div className={"about-story"}>
                <h2 className='our-story-title'>Our Story</h2>
                {/* {
                    stories.map((story, index) => {
                        return <StorySection text={story} final={index === (stories.length - 1) ? true : false} />
                    })
                } */}
                <OurStory content={stories}/>
            </div>
            <div className={"about-leadership"}>
                <div className={"about-leadership-img"}>
                    <img src={"/assets/about/leader.png"} alt={"Paraqum Leadership"} />
                </div>

                <div className={"about-leadership-details"}>
                    <div>
                        <h2>Paraqum Leadership</h2>
                        <p>Dr. Ajith Pasqual is the founder and CEO of Paraqum Technologies. He is a senior academic with close to 25 years’ of experience at the Department of Electronic & Telecommunication Engineering at University of Moratuwa, the premier engineering university in Sri Lanka. He has served as the Head of Department of Electronic & Telecommunication Engineering and also as Director/Center for Information Technology Services at the same university. He received his B.Sc. Engineering degree with First Class Honours from University of Moratuwa, Sri Lanka in Electronic & Telecommunication Engineering in 1993, M.Eng. and Ph.D. degrees from The University of Tokyo in Computer Vision in 1998 and 2001 respectively. His primary research interests are in Computer Vision, Application Processors and SoC Architectures and he leads the Reconfigurable Digital Systems Research Group at the University of Moratuwa which work in the area of hardware acceleration, novel architectures for application specific processors and SoCs to improve performance and power efficiency. Since 2004, he has been focusing heavily on reconfigurable logic systems and their applications in Computer Vision, Video Compression, Mobile Communications, Networking and Bio Signal Processing. He is driving the initiative to create an Advanced Electronics Design Industry in Sri Lanka and was instrumental in setting up the Facilitation Center for Advanced Electronics Design at University of Moratuwa to support startup companies in Electronics.</p>
                        <div className={"leader-social"}>
                            <a href={"/#"}>
                                <img src={"/assets/about/linkedin.png"} alt={"Leader Likedin"} />
                            </a>

                            <a href={"/#"}>
                                <img src={"/assets/about/gmail.png"} alt={"Leader Email"} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
}

const StorySection = (props) => {
    return <div className={"story-section"}>
        <div className={"dot-container"}>
            {props.final === true ? <div className={"circle"}></div> : null}
            <div className={"outer"}></div>
        </div>

        <p>{props.text}</p>
    </div>
}

const OurStory = ({content}) => {
return (
    <div className='our-story'>

        <div className='our-story-timeline'>

            <div className='our-story-container our-story-container-left'>
                <div className='our-story-text-box'>
                    <h2 >{content[0].split('/')[0]}</h2>
                    <p> {content[0].split('/')[1]}</p>
                     
                </div>
            </div>

            <div className='our-story-container our-story-container-right'>
                <div className='our-story-text-box'>
                <span className='our-story-container-right-arrow'></span>
                <h2 >{content[1].split('/')[0]}</h2>
                    <p> {content[1].split('/')[1]}</p>                    

                </div>
            </div>

            <div className='our-story-container our-story-container-left' >
                <div className='our-story-text-box'>
                <h2 >{content[2].split('/')[0]}</h2>
                    <p> {content[2].split('/')[1]}</p>                    
                    

                </div>
            </div>

            <div className='our-story-container our-story-container-right'>
                <div className='our-story-text-box'>
                <span className='our-story-container-right-arrow'></span>

                <h2 >{content[3].split('/')[0]}</h2>
                    <p> {content[3].split('/')[1]}</p>                    

                </div>
            </div>

            <div className='our-story-container our-story-container-left'>
                <div className='our-story-text-box'>
                <h2 >{content[4].split('/')[0]}</h2>
                    <p> {content[4].split('/')[1]}</p>                    
                    

                </div>
            </div>

            <div className='our-story-container our-story-container-right'>
                <div className='our-story-text-box'>
                <h2 >{content[5].split('/')[0]}</h2>
                    <p> {content[5].split('/')[1]}</p>                    


                </div>
            </div>

            <div className='our-story-container our-story-container-left'>
                <div className='our-story-text-box'>
                <h2 >{content[6].split('/')[0]}</h2>
                    <p> {content[6].split('/')[1]}</p>                    
                   

                </div>
                
            </div>

            {/* <div className='our-story-container our-story-container-right'>
                <div className='our-story-text-box'>
                <h2 >{content[7].split('/')[0]}</h2>
                    <p> {content[7].split('/')[1]}</p>                    
                   

                </div>
                
            </div> */}
        </div>

    </div>  
)
}

export default About;