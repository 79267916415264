import React from 'react';
import { Helmet } from "react-helmet";
import ProductTemplate from '../../../../templates/productNew';
import Carousel from './carousel';

const Product = () => {
    const title = "Paraqum Traffic Shaper";
    const watermark = "TRAFFIC SHAPER";
    const mockup = "/assets/screens/trafficShaper/DeviceMockup/trafficShaperMockup.png"

    const features = [
        {
          title: "DPI-based real-time network analytics",
          description: "Paraqum Traffic Shaper provides real-time network analytics using Deep Packet Inspection (DPI) from Layer 2 to Layer 7, optimizing bandwidth management for ISPs facing challenges in revenue decline and increased demand for higher bandwidth."
        },
        {
          title: "1G/10G/100G ethernet interfaces with failsafe design",
          description: "Ensure network stability and reliability with Paraqum Traffic Shaper's support for various ethernet interfaces and failsafe design, allowing ISPs to smoothly transition with traffic growth and meet user demands without compromising performance."
        },
        {
          title: "Traffic prioritization per user preference",
          description: "Optimize Quality of Experience (QoE) with Paraqum Traffic Shaper's traffic prioritization based on user preferences. ISPs can offer a personalized internet experience, differentiating themselves based on service quality."
        },
        {
          title: "Rate controlling based on bandwidth limitations",
          description: "Paraqum Traffic Shaper enables ISPs to control network traffic by implementing rate limiting based on maximum and guaranteed bandwidth limits. This ensures efficient bandwidth usage and prevents congestion."
        },
        {
          title: "Multi-level shaping",
          description: "Achieve advanced traffic shaping with multi-level support, allowing ISPs to prioritize and control applications, services, and URLs. The shaping tree structure ensures a granular level of control over bandwidth utilization."
        },
        {
          title: "Time and quota-based bandwidth controlling",
          description: "Efficiently manage bandwidth usage with time and quota-based policies. Paraqum Traffic Shaper allows ISPs to enforce policies for specific time periods, ensuring fair usage and dynamic adjustment of quota limits."
        },
        {
          title: "Separate user portal for subscriber login",
          description: "Enhance subscriber engagement with Paraqum Traffic Shaper's separate user portal. ISPs can offer value-added services, empowering subscribers to control their internet package preferences and ensuring a positive user experience."
        },
        {
          title: "Bandwidth controlling as a VAS",
          description: "Leverage Paraqum Traffic Shaper as a Value-Added Service (VAS) for ISPs. Effectively manage bandwidth, enforce QoS parameters, and create personalized subscriber packages, addressing the diverse needs of both corporate and domestic users."
        },
      ];
      

    const description = "Paraqum Traffic Shaper will give you unprecedented control over your network enabling you to inspect, analyze and enforce rules to get the desired productivity in network utilization through bandwidth management. It also allows you to provide traffic shaping as a service to multiple clients using a single device.";

    const screens = [
        {
            img: "/assets/screens/trafficShaper/screen1.jpeg",
            desc: "",
        },
        {
            img: "/assets/screens/trafficShaper/screen2.jpeg",
            desc: "",
        },
        {
            img: "/assets/screens/trafficShaper/screen3.jpeg",
            desc: "",
        }
    ];

    return <div>
        <Helmet>
            <title>Traffic Shaper</title>
            <meta
                name="description"
                content="Traffic Shaper"
            />
            <meta
                name="keywords"
                content="Traffic shaping,network traffic management,bandwidth management,network traffic analysis,traffic analytics,network traffic control,Network Quality of Experience ,Network QoE,Network QoE Assurance,network traffic optimization,network traffic optimisation,network intelligence,network Quality of Service,network QoS,Session Quality Index,SQI,burst control,hierarchical shaping ,Multi-level shaping,multi level shaping,network fair use policy,FUP,FUP enforcement,fair use policy enforcement,guaranteed bandwidth,traffic priority,bufferbloat,CAKE SQM,CAKE AQM,fair queuing"
            />
        </Helmet>
        <ProductTemplate
            title={title}
            features={features}
            description={description}
            screens={screens}
            slideshow={<Carousel />}
            watermark={watermark}
            mockup={mockup}

        />
    </div>
}

export default Product;