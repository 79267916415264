import React from 'react'
import { ImageSlider } from '../wispapalooza/sections/ImageSlider'
import CardCarousel from './CardCarousel'
import ImageSliderCmp from "../../templates/components/ImageSliderCmp.jsx"
import eventData from "./eventData.json";
import { useParams } from 'react-router-dom';


const PreviousEventPage = () => {

  const { eventId } = useParams(); // Fetching the eventId from the URL
  const event = eventData.find(e => e.id === eventId); // Finding the event matching the eventId

  if (!event) {
    return <div>Event not found!</div>;
  }
  
  return (
    <div>


<div className='wispamericaTop'>
<div style={{ backgroundImage: `url(${event.bgImageLink})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} className='wispamericaTop-container'>
          <img width={'200px'} src ={event.logo} />
<div className='wispamericaTop-header-middle'>
          <p className='wispamericaTop-oklahoma'> {event.venue} </p>
          <h2 className='wispamericaTo-meetus' style={
    event.venue === "BANGKOK" ? { marginLeft: '120px' } :
    event.venue === "LAS VEGAS" ? { marginLeft: '40px' } :
    event.venue === "KENTUCKY" ? { marginLeft: '40px' } :
    event.venue === "SINGAPORE" ? { marginLeft: '250px' } :
    {}
  }>{event.eventName}<span className='wispamericaTop-person'> {event.eventYear}</span></h2>
          </div>

          <h2 className='wispamericaTo-meetus-mobile' style={{display:'none'}}>{event.eventName}<span className='wispamericaTop-person'> {event.eventYear}</span></h2>
          <p className='wispamericaTop-text'>{event.subtitle}</p>
        </div>
      </div>


      <div className='PreEvent-middle'>

 <div className='PreEvent-middle-container'>
 <div className='PreEvent-middle'>

  <h3>Event Success</h3>
        
<p> {event.description} </p>

        </div>


      </div>

    <ImageSliderCmp imageUrls={event.images}/>



      </div>
      
    </div>
  )
}

export default PreviousEventPage
