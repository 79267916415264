import React from 'react'

export const WidiStandout = () => {
  const WidiStandout = [
    {
      feature: "Session Quality Monitoring",
      image: "/assets/wispapalooza/WISPAPALOOZA 2022_Session Quality Monitoring_copy.png",
      description: "Allows you to monitor and index the reliability of sessions in order to ensure the highest QoE for your subscribers",
    },
    {
      feature: "FUP Enforcement",
      image: "/assets/wispapalooza/WISPAPALOOZA 2022_FUP Enforment_copy.png",
      description: "Assures the proper enforcement of FUP with easy identification of FUP violators and guaranteed QoE for every subscriber.",
    },
    {
      feature: "1 Millisecond Resolution",
      image: "/assets/wispapalooza/WISPAPALOOZA 2022_Millisecond Resolution_copy.png",
      description: "Provides an extremely granular bandwidth monitoring capability down to 1 millisecond interval.",
    },
    
    {
      feature: "TCP Acceleration Support",
      image: "/assets/wispapalooza/WISPAPALOOZA 2022_TCP Acceleration_copy.png",
      description: "Improves subscriber QoE and network efficiency, without making changes to the network infrastructure or modifying the end applications.",
    },
    {
      feature: "Multi-Level and Priority Based Shaping",
      image: "/assets/wispapalooza/WISPAPALOOZA 2022_Multi-Level Shaping_copy.png",
      description: "Maps the infrastructure topology: from subscriber plan through sector, backhaul, and top link limits.",
    },
    {
      feature: "State-of-the-art Deep Packet Inspection (DPI) Technology",
      image: "/assets/wispapalooza/WISPAPALOOZA 2022_DPI Tech_copy.png",
      description: "Application-aware Traffic Shaping that allows precise control of application traffic, offering the best QoE for all applications and subscribers.",
    },
  ];
  
  return (
    <div className='Wispamerica-widistandout'>
        
        <p className='Wispamerica-widistandout-title'>Why choose Paraqum Wi-Di?</p>
        <p className='Wispamerica-widistandout-subtitle'>Here’s why you should try out our solution,
 be the change your subscribers want to see.</p>

 <div className='Wispamerica-widistandout-grid'>
  
  { WidiStandout.map((features,index) => {
return(
  <div className='Wispamerica-widistandout-feature'>
  <div className='Wispamerica-widistandout-circle'>
    <img width={'90px'} src={features.image}/>
  </div>
  <p className='Wispamerica-widistandout-topic'>
  {features.feature}
  </p>

  <p className='Wispamerica-widistandout-discription'>
  {features.description}
  </p>

</div>
)
  })


  }






 </div>
        </div>
  )
}
