import React from 'react'
import Header from '../../components/header';
import Footer from '../../components/footer';
import Page from './page';


const PrivacyPolicy = () => {
  return (
    <div>

        <Header/>
        <Page/>
        <Footer/>
      
    </div>
  )
}

export default PrivacyPolicy;
