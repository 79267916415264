import React, { useRef, useState } from 'react';
import Header from '../components/header';
import Connector from './components/connector';
import Footer from '../components/footer';
import Demo from '../components/demo';
import DemoLite from '../components/demowidiLite';
import Tooltip from './components/ToolTip';

//Product New Template


const ProductTemplate = (props) => {
    const webRef = useRef();
    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
        document.body.classList.add('active-modal');
      };
    
      const closeModal = () => {
        setModalOpen(false);
        document.body.classList.remove('active-modal');
      };

      
    return <div>

        
        <div className={"product-template"}>
            <Header/>

            <div className='product-header-wrapper' style={{ backgroundImage: `url("/assets/screens/bgImage.jpg")` }}>

                <div className={"product-wrapper"} style={{ marginTop: '40px' }} >
                    <h1 className='product-title'>{props.title}</h1>
                    {/* <p>EX-SERIES</p> */}
                    <p className={
                        props.watermark === "CEYMARSHAL" ||
                            props.watermark === "CEYANALYST" ||
                            props.watermark === "CEYBROKER" ||
                            props.watermark === "CEYGALAXY" ||
                            props.watermark === "CEYDIRECTi"
                            ? "product-watermark-ceyseries"
                            : props.watermark === "TRAFFIC SHAPER"  
                                ? "product-watermark-trafficshaper" 
                                : props.watermark === "NETWORK ANALYZER"  
                                ? "product-watermark-networkanalyzer" 
                                : props.watermark === "SUBSCRIBER MANAGER" 
                                ? "product-watermark-submanager" :
                                props.watermark === "WI-DI" 
                                ? "product-watermark-widi" :
                                "product-watermark-defualt"
                    }>
                        {props.watermark}
                    </p>

                    {/* <p className='product-description'>{props.description}</p> */}
                    <div className='product-buttonNewWrapper'><button onClick={() => webRef.current.scrollIntoView()} loading="lazy" className='product-buttonNew'>KEY FEATURES</button></div>
                </div>

                <div className='product-mockup'>
                    <img width={'90%'} src={props.mockup}/>
                </div>
            </div>

            <div style={{marginTop:'30px', display:'flex', justifyContent:'center'}}>
            <p className='product-header-wrapper-p'>{props.description}</p>
            </div>

           
            <div className={"productNew-header"}>
                {/* <img src={"/assets/icons/product/pqBtn.svg"} alt={"pq button"} className={"pq-scroll-button"}/> */}
                <div className={"product-web-button"}>
                    <div className={"product-feature-left"}></div>
                    <div className={"product-feature-mid"}  ref={webRef} >
                        <img src={"/assets/product/boneHeader.svg"} alt={"Product web button"} onClick={()=>webRef.current.scrollIntoView()} loading="lazy"/>
                    </div>
                    <div className={"product-feature-right"}></div>
                </div>
            </div>


            <div className={"product-mobile-features"}>
                <div className={"product-features"}>
                {props.features.map((feature,index)=>{
                    return <div className={"product-feature-diagram"}>
                        {index > 0 ? <Connector/> : null}
                        <Tooltip key={index} align={"middle"}  text={feature.description} >
                        <p>{feature.title}</p>
                        </Tooltip>
                    </div>
                })}
                </div>
            </div>

            <div className={"product-web-features"}>
                <div className={"product-feature-left"}>
                    {
                        props.features.map((feature,index)=>{
                            const flen = feature.title.split(' ').length;
                            const size = flen <= 2 ? "small":  flen <= 5 ? "medium": "large";
                            return index%2 === 0 ? <div className={"product-item"}>
                               <Tooltip key={index} align={"left"} size={size} text={feature.description} >
<p >{feature.title}</p></Tooltip>
                                </div> : <div className={"product-item"}></div>
                        })
                    }
                </div>
                <div className={"product-feature-mid"}>
                    {
                        props.features.map((feature,index)=>{
                            return index === (props.features.length - 1) &&  index%2 === 0 ? 
                            <div className={"product-item"}><img src={"/assets/product/boneLeftEnd.svg"} alt={"Left End"} loading="lazy"/></div> 
                            : index === (props.features.length - 1) &&  index%2 === 1 ? 
                            <div className={"product-item"}><img src={"/assets/product/boneRightEnd.svg"} alt={"Right End"} loading="lazy"/></div> 
                            : index%2 === 1 ? 
                            <div className={"product-item"}><img src={"/assets/product/boneRightMid.svg"} alt={"Right Mid"} loading="lazy"/></div> 
                            : <div className={"product-item"}><img src={"/assets/product/boneLeftMid.svg"} alt={"Left End"} loading="lazy"/></div> 
                        })
                    }
                </div>
                <div className={"product-feature-right"}>
                    {
                        props.features.map((feature,index)=>{
                            const flen = feature.title.split(' ').length;
                            const size = flen <= 2 ? "small":  flen <= 7 ? "medium": "large";
                            return index%2 === 1 ? <div className={"product-item"}>
                           <Tooltip key={index} size={size} align={"right"} text={feature.description} >
                             <p>{feature.title}</p>
                             </Tooltip>  
                                
                                </div> : <div className={"product-item"}></div>
                        })
                    }
                </div>
            </div>

            {props.slideshow}

            <div className={"product-screens mobile"}>
                <h4>How It Looks</h4>
                {props.screens.map((screen, index)=>{
                    return <div className={"product-screen"}>
                        <img src={screen.img} alt={"screen_"+index} loading="lazy"/>
                        <p><i>{screen.desc}</i></p>
                    </div>
                })}
            </div>

            {/* 
                {props.terms === true && 
                    // Render the following content only if props.terms is true
                    <div className={"product-terms-container"}>
                        {/* Left section with policy titles */}
                        {/* 
                        <div className={"product-terms-left"}>
                            <p className={"product-terms-policy1"}> 1. Refund Policy </p>
                            <p> 2. Cancellation Policy </p>
                        </div>
                
                        {/* Vertical line separator */}
                        {/* 
                        <div className={"product-terms-vl"}>
                            {/* This div is used to create a vertical line */}
                        {/* </div>
                
                        {/* Right section with terms of services */}
                        {/* 
                        <div className={"product-terms-right"}>
                            {/* Main heading */}
                            {/* <h1>Terms of Services</h1>
                
                            {/* Additional information */}
                            {/* <p className={"product-terms-right-bottom"}>
                                **Refund policies and cancellation policies of ISP customers with contracts are included in the respective contractual agreement.
                            </p>
                
                            {/* Refund Policy section */}
                            {/* <h2>1. Refund Policy</h2>
                            <p>
                                At Paraqum Technologies (Pvt) Ltd, we are committed to ensuring customer satisfaction with our products and services. If there is an overpayment for the invoice, you may request a refund within the respective billing month. Approved refunds can be processed in one of two ways: a direct refund to your bank account, where the amount refunded will have any Stripe transaction fees deducted, or by allocating the refund amount to your next billing month without any deductions (i.e. considered as an advance payment for the next month/s). To initiate a refund, please contact our customer support team at support@paraqum.com with your invoice. We will review your request and notify you of the outcome within 2 business days. Thank you for your understanding and cooperation.
                            </p>
                
                            {/* Cancellation Policy section */}
                            {/* <h2>2. Cancellation Policy</h2>
                            <p>
                                Our cancellation policy allows you to cancel your subscription or service at any time without any hassle. To cancel, simply contact our customer support team at support@paraqum.com with your account details. Please allow 2 business days for the cancellation to be processed. After the cancellation, the payment will not be refunded and the license will be deactivated at the end of the respective month.
                            </p>
                        </div>
                    </div>
                }
                */}
            

            <div className={"product-download"}>
                <div className={"product-download-banner"}>
                    <img src={"/assets/icons/product/download.svg"} alt={"product download left banner"} loading="lazy"/>
                </div>
                <div className={"product-download-button"}>
                    <a href={"/datasheets"} download={props.filename}><button className='btn download-datasheet-btn'>Download Datasheet </button></a>
                </div>
                <div className={"product-download-banner"}>
                    <img src={"/assets/icons/product/sheet.svg"} alt={"product download right banner"} loading="lazy"/>
                </div>
            </div>
{props.title === "Paraqum Wi-Di Lite"? <DemoLite />: <Demo/> }
            
            <Footer/>
        </div>
    </div>
}

export default ProductTemplate;